<template>
    <ul>
        <template v-for="item in navItems">
            <nav-item :liClass="liClass" :name="item.name" :url="item.url" :children="item.children" />
        </template>
    </ul>
</template>

<script>
    export default {
        name: 'navMenu',
        props: {
            navItems: {
                type: Array,
                required: true,
                default: () => []
            },
            ulClass: {
              type: String,
              required: false,
              default: () => ''
            },
            liClass: {
              type: String,
              required: false,
              default: () => ''
            }
        }
    }
</script>
