<template>
  <section id="cartView">
    <table class="wrapper-border w-full p-4">
      <tr v-for="product in order">
        <td>{{product.name}}</td><td>{{product.pricing.CAD.monthly}}</td><td> <i class="fa fa-close text-red" @click="remove(product)"></i></td>
      </tr>
    </table>
  </section>
</template>

<script>
  export default {
    name: 'cartView',
    computed: {
      order() {
        return this.$store.getters['cart/order']
      }
    },
    methods: {
      remove: function(product) {
        this.$store.dispatch('cart/remove', this.order.indexOf(product));
        this.$nextTick()
      }
    }
  }
</script>
