import Axios from 'axios'
import Vue from 'vue'
import Support from '../modules/clientarea/support'

Vue.use(Axios)

export default {
  namespaced: true,
  state: {
    auth: {loggedIn: false},
    user_data: {},
    services: {},
    domains: {},
    stats: {}
  },
  mutations: {
    setLogin: function(state, auth) {
      state.auth = auth.auth
      state.auth.loggedIn = true
    },
    logout: function(state) {
      state.auth = {}
      state.user_data = {}
      state.domains = {}
      state.services = {}
      state.auth.loggedIn = false
    },
    setUserData: function(state, data) {
      state.user_data = data.client
      state.stats = data.stats
    },
    setUserServices: function(state, data) {
      state.services = data.products
    },
    setUserDomains: function(state, data) {
      state.domains = data.domains
    },
  },
  actions: {
    login: function(context, auth) {
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
        headers: {
            Authorization: context.rootState.config.basicAuth
        },
        params: {
            username: context.rootState.config.username,
            password: context.rootState.config.password,
            accesskey: context.rootState.config.accesskey,
            action: 'ValidateLogin',
            email: auth.auth.email,
            password2: auth.auth.password,
            responsetype: 'json'
        },
        withCredentials: true
      }).then((response) => {
          if(response.data.result == 'error')
          {
            context.commit('setError', {active: true, message: response.data.message}, {root:true})
          }
          else
          {
            context.dispatch('grabDetails', {user_id:response.data.userid, passhash: response.data.passwordhash})
            context.dispatch('grabServices', {user_id:response.data.userid, passhash: response.data.passwordhash})
            context.dispatch('grabDomains', {user_id:response.data.userid, passhash: response.data.passwordhash})
            context.commit('setLogin', {auth:response.data}, {root:false})
          }
      })
    },
    logout: function(context) {
      context.commit('logout')
    },
    grabDetails: function(context, data) {
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
        headers: {
            Authorization: context.rootState.config.basicAuth
        },
        params: {
            username: context.rootState.config.username,
            password: context.rootState.config.password,
            accesskey: context.rootState.config.accesskey,
            action: 'GetClientsDetails',
            clientid: data.user_id,
            stats: true,
            responsetype: 'json'
        },
        withCredentials: true
        }).then((response) => {
          /*console.info(response.data)*/
            context.commit('setUserData', response.data, {root:false})
        })
    },
    grabServices: function(context, data) {
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
        headers: {
            Authorization: context.rootState.config.basicAuth
        },
        params: {
            username: context.rootState.config.username,
            password: context.rootState.config.password,
            accesskey: context.rootState.config.accesskey,
            action: 'GetClientsProducts',
            clientid: data.user_id,
            responsetype: 'json'
        },
        withCredentials: true
        }).then((response) => {
            if(response.data.products !== "") {
              context.commit('setUserServices', response.data, {root:false})
            }
        })
    },
    grabDomains: function(context, data) {
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
        headers: {
            Authorization: context.rootState.config.basicAuth
        },
        params: {
            username: context.rootState.config.username,
            password: context.rootState.config.password,
            accesskey: context.rootState.config.accesskey,
            action: 'GetClientsDomains',
            clientid: data.user_id,
            responsetype: 'json'
        },
        withCredentials: true
        }).then((response) => {
          if( response.data.domains !== "") {
            context.commit('setUserDomains', response.data, {root:false})
          }
      })
    }
  },
  getters: {
    isLogged: function(state) {
        return state.auth.loggedIn
    },
    details: function(state) {
        return state.user_data
    }
  },
  modules: {
    support: Support
  }
}
