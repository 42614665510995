var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "login" } }, [
    _c("div", { staticClass: "flex mt-10" }, [
      _c("div", { staticClass: "flex-1" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-1 wrapper-border px-4 py-4 text-center text-white"
        },
        [
          _vm.error.active == true
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-red-lightest border border-red-light text-red-dark px-4 py-3 rounded relative",
                    attrs: { role: "alert" }
                  },
                  [
                    _c("strong", { staticClass: "font-bold" }, [
                      _vm._v("Error")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "block sm:inline" }, [
                      _vm._v(_vm._s(_vm.error.message))
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "absolute pin-t pin-b pin-r px-4 py-3" },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "fill-current h-6 w-6 text-red",
                            attrs: {
                              role: "button",
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 20 20"
                            },
                            on: { click: _vm.closeError }
                          },
                          [
                            _c("title", [_vm._v("Close")]),
                            _c("path", {
                              attrs: {
                                d:
                                  "M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            staticClass: "px-8 py-4",
            attrs: { src: require("../assets/img/wlogo.png") }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "text-white text-center" }, [
            _vm._v("Login")
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c("fieldset", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.name,
                      expression: "user.name"
                    }
                  ],
                  attrs: {
                    type: "text",
                    size: "20",
                    placeholder: "Email Address"
                  },
                  domProps: { value: _vm.user.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "name", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("fieldset", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.passw,
                      expression: "user.passw"
                    }
                  ],
                  attrs: {
                    type: "password",
                    size: "20",
                    autocomplete: "off",
                    placeholder: "Password"
                  },
                  domProps: { value: _vm.user.passw },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "passw", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("fieldset", [
      _c(
        "button",
        {
          staticClass:
            "bg-blue hover:bg-blue-dark text-black font-bold py-2 px-4 rounded",
          attrs: { type: "submit" }
        },
        [_vm._v("Login")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }