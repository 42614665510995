<template>
<section id="contact-form">
  <div class="flex mt-10">
      <div class="flex-1"></div>
      <div class="flex-1">
      <div class="page-header wrapper-border m-2 p-2">
        <h2 class="text-white text-center">Contact Form</h2>
      </div>
      <div class="wrapper-border p-2 m-2 text-center">
        <form @submit.prevent="handleSubmit">
          <fieldset><input type="text" name="name" v-model="form.name" placeholder="Name" /></fieldset>
          <fieldset><input type="email" name="email" v-model="form.email" placeholder="E-mail" /></fieldset>
          <fieldset><textarea rows="6" cols="30" v-model="form.message" placeholder="Type your message here..."></textarea></fieldset>
          <fieldset><button class="bg-blue hover:bg-blue-dark text-black font-bold py-2 px-4 rounded" type="submit">Send</button></fieldset>
        </form>
      </div>
    </div>
    <div class="flex-1"></div>
  </div>
</section>
</template>

<script>
export default {
  name: 'contact-form',
  data() {
    return {
      form: {
        name: this.user.name,
        email: this.user.email,
        messasge: ''
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['client/details']
    }
  },
  methods: {
    handleSubmit() {
        this.$store.dispatch('client/login', {auth: { email: this.user.name, password: this.user.passw}})
        if(this.$store.getters['client/isLogged'] === true) {
          this.$router.push({name: 'manage'})
          this.$router.go(1)
        }
    },
  }
}
</script>
