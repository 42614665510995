var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.navItems, function(item) {
        return [
          _c("nav-item", {
            attrs: {
              liClass: _vm.liClass,
              name: item.name,
              url: item.url,
              children: item.children
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }