import Axios from 'axios'
import Vue from 'vue'

Vue.use(Axios)

export default {
  namespaced: false,
  state: {
    tickets:[]
  },
  action: {
    grabTickets: function(context, data) {
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
        headers: {
            Authorization: context.rootState.config.basicAuth
        },
        params: {
            username: context.rootState.config.username,
            password: context.rootState.config.password,
            accesskey: context.rootState.config.accesskey,
            action: 'GetTickets',
            clientid: data.user_id,
            responsetype: 'json'
        },
        withCredentials: true
        }).then((response) => {
          console.log(response.data)   
            if( response.data !== "") {
              context.commit('setUserDomains', response.data, {root:false})
            }
        })
      }
    }
  }
