var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { id: "home" } },
    [
      _c("Slider"),
      _vm._v(" "),
      _c("Featured"),
      _vm._v(" "),
      _c("Announcement"),
      _vm._v(" "),
      _c("DomainChecker"),
      _vm._v(" "),
      _c("WebPanel"),
      _vm._v(" "),
      _c("WebTools"),
      _vm._v(" "),
      _c("TextBoxes")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }