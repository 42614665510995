var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "dedicated_hosting" } }, [
      _c("div", { staticClass: "w-full page-header" }, [
        _c("h1", { staticClass: "text-gold text-center py-2" }, [
          _vm._v("Dedicated Hosting")
        ])
      ]),
      _vm._v(" "),
      _c("section", { attrs: { id: "dedicatedservers" } }, [
        _c("div", { staticClass: "container mx-auto wrapper-border p-4" }, [
          _c("div", {}, [
            _c("div", {}, [
              _c("table", { staticClass: "w-full" }, [
                _c("tr", [
                  _c("th", [_vm._v("Server")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Processor")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Hard Drive")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("RAM")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Price")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Build")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("img", {
                      staticClass: "img-responsive wow zoomInLeft",
                      attrs: {
                        width: "173",
                        src: require("../../assets/img/dedicated/Xeon.png")
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("Intel Xeon D"),
                    _c("br"),
                    _vm._v("D-1520\t4/8t"),
                    _c("br"),
                    _vm._v("2,2 / 2,6 GHz")
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v("2x2 TB"), _c("br"), _vm._v(" SOFT/JBOD")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("32 GB"),
                    _c("br"),
                    _vm._v("DDR4 ECC "),
                    _c("br"),
                    _vm._v("2133 MHz")
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v("$109.00 p/m")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-main",
                        attrs: { href: "cart.php", role: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-server" }),
                        _vm._v("Configure")
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "center", attrs: { id: "xshidden" } },
                    [
                      _c("img", {
                        staticClass: "img-responsive wow zoomInLeft",
                        attrs: {
                          src: require("../../assets/img/dedicated/icon2.png")
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("Dual Core")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("2.5 TB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("4 GB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("$69.99 p/m")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-main",
                        attrs: { href: "cart.php", role: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-server" }),
                        _vm._v("Configure")
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "center", attrs: { id: "xshidden" } },
                    [
                      _c("img", {
                        staticClass: "img-responsive wow zoomInLeft",
                        attrs: {
                          src: require("../../assets/img/dedicated/icon3.png")
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("Quad Core")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("3 TB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("4 GB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("$79.99 p/m")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-main",
                        attrs: { href: "cart.php", role: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-server" }),
                        _vm._v("Configure")
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "center", attrs: { id: "xshidden" } },
                    [
                      _c("img", {
                        staticClass: "img-responsive wow zoomInLeft",
                        attrs: {
                          src: require("../../assets/img/dedicated/icon4.png")
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("Quad Core")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("3.5 TB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("4 GB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("$89.99 p/m")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-main",
                        attrs: { href: "cart.php", role: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-server" }),
                        _vm._v("Configure")
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "center", attrs: { id: "xshidden" } },
                    [
                      _c("img", {
                        staticClass: "img-responsive wow zoomInLeft",
                        attrs: {
                          src: require("../../assets/img/dedicated/icon5.png")
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("Quad Core")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("4 TB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("4 GB")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [_vm._v("$99.99 p/m")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-lg btn-main",
                        attrs: { href: "cart.php", role: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-server" }),
                        _vm._v("Configure")
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { attrs: { id: "dedicatedtable" } }, [
        _c("div", { staticClass: "container" }, [
          _c("h3", { staticClass: "intro wow fadeInLeft" }, [
            _vm._v("Dual Core Servers:")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "light wow fadeInLeft" }, [
            _vm._v(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae consectetur adipiscing elit. Sed vitae iaculis sapien. Phasellus ultrices gravida massa luctus ornare."
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "table-responsive wow zoomInDown" }, [
            _c("table", { staticClass: "table table-striped" }, [
              _c("tr", [
                _c("th", { attrs: { width: "25%" } }, [_vm._v("Server")]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "10%" } }, [
                  _vm._v("CPU")
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "10%" } }, [
                  _vm._v("Memory")
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "10%" } }, [
                  _vm._v("Bandwidth")
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "10%" } }, [
                  _vm._v("Port Speed")
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "10%" } }, [
                  _vm._v("Price")
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "10%" } }, [
                  _vm._v("Setup")
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "center", attrs: { width: "15%" } }, [
                  _vm._v("Order")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("i", { staticClass: "fa fa-arrow-circle-right" }),
                  _vm._v("Intel Atom D525")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("4 x 1.8 ghz")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8GB DDR3")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("10 TB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("1 Gbps")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("$79.99 p/m")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("24 hours")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-blue",
                      attrs: { href: "cart.php", role: "button" }
                    },
                    [_vm._v("Order Today")]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "intro wow fadeInLeft" }, [
            _vm._v("Quad Core Servers:")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "light wow fadeInLeft" }, [
            _vm._v(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae consectetur adipiscing elit. Sed vitae iaculis sapien. Phasellus ultrices gravida massa luctus ornare."
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "table-responsive wow zoomInDown" }, [
            _c("table", { staticClass: "table table-striped" }, [
              _c("tr", [
                _c("th", [_vm._v("Server")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("CPU")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("Memory")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("Bandwidth")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("Port Speed")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("Price")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("Setup")]),
                _vm._v(" "),
                _c("th", { staticClass: "center" }, [_vm._v("Order")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("i", { staticClass: "fa fa-arrow-circle-right" }),
                  _vm._v("Intel Core i5 4570")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("4 x 3.2 ghz")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8GB - 32GB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("10 TB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("1 Gbps")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("$79.99 p/m")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("48 hours")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-blue",
                      attrs: { href: "cart.php", role: "button" }
                    },
                    [_vm._v("Order Today")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("i", { staticClass: "fa fa-arrow-circle-right" }),
                  _vm._v("Intel Core i7 4770")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8 x 3.4 ghz")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8GB - 32GB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("10 TB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("1 Gbps")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("$84.99 p/m")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("48 hours")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-blue",
                      attrs: { href: "cart.php", role: "button" }
                    },
                    [_vm._v("Order Today")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("i", { staticClass: "fa fa-arrow-circle-right" }),
                  _vm._v("Intel Core i7 4790")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8 x 3.6 ghz")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8GB - 32GB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("10 TB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("1 Gbps")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("$89.99 p/m")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("48 hours")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-blue",
                      attrs: { href: "cart.php", role: "button" }
                    },
                    [_vm._v("Order Today")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("i", { staticClass: "fa fa-arrow-circle-right" }),
                  _vm._v("Int. Xeon E3-1230v3")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8 x 3.3 ghz")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("8GB - 32GB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("10 TB")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("1 Gbps")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("$99.99 p/m")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [_vm._v("48 hours")]),
                _vm._v(" "),
                _c("td", { staticClass: "center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-blue",
                      attrs: { href: "cart.php", role: "button" }
                    },
                    [_vm._v("Order Today")]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }