<template>
    <section id="dedicated_hosting">
        <div class="w-full page-header">
            <h1 class="text-gold text-center py-2">Dedicated Hosting</h1>
        </div>
        <!--Begin Dedicated Servers-->
<section id="dedicatedservers">
  <div class="container mx-auto wrapper-border p-4">
    <div class="">
      <!--Begin Plans Table-->
      <div class="">
        <table class="w-full">
          <tr>
            <th>Server</th>
            <th>Processor</th>
            <th>Hard Drive</th>
            <th>RAM</th>
            <th>Price</th>
            <th>Build</th>
          </tr>
          <!--Begin Plan-->
          <tr>
            <td><img width="173" src="../../assets/img/dedicated/Xeon.png" class="img-responsive wow zoomInLeft"></td>
            <td>Intel Xeon D<br />D-1520	4/8t<br />2,2 / 2,6 GHz</td>
            <td>2x2 TB<br /> SOFT/JBOD</td>
            <td>32 GB<br />DDR4 ECC <br />2133 MHz</td>
            <td>$109.00 p/m</td>
            <td><a href="cart.php" class="btn btn-lg btn-main" role="button"><i class="fa fa-server"></i>Configure</a></td>
          </tr>
          <!--End Plan-->
          <!--Begin Plan-->
          <tr>
            <td class="center" id="xshidden"><img src="../../assets/img/dedicated/icon2.png" class="img-responsive wow zoomInLeft"></td>
            <td class="center">Dual Core</td>
            <td class="center">2.5 TB</td>
            <td class="center">4 GB</td>
            <td class="center">$69.99 p/m</td>
            <td class="center"><a href="cart.php" class="btn btn-lg btn-main" role="button"><i class="fa fa-server"></i>Configure</a></td>
          </tr>
          <!--End Plan-->
          <!--Begin Plan-->
          <tr>
            <td class="center" id="xshidden"><img src="../../assets/img/dedicated/icon3.png" class="img-responsive wow zoomInLeft"></td>
            <td class="center">Quad Core</td>
            <td class="center">3 TB</td>
            <td class="center">4 GB</td>
            <td class="center">$79.99 p/m</td>
            <td class="center"><a href="cart.php" class="btn btn-lg btn-main" role="button"><i class="fa fa-server"></i>Configure</a></td>
          </tr>
          <!--End Plan-->
          <!--Begin Plan-->
          <tr>
            <td class="center" id="xshidden"><img src="../../assets/img/dedicated/icon4.png" class="img-responsive wow zoomInLeft"></td>
            <td class="center">Quad Core</td>
            <td class="center">3.5 TB</td>
            <td class="center">4 GB</td>
            <td class="center">$89.99 p/m</td>
            <td class="center"><a href="cart.php" class="btn btn-lg btn-main" role="button"><i class="fa fa-server"></i>Configure</a></td>
          </tr>
          <!--End Plan-->
          <!--Begin Plan-->
          <tr>
            <td class="center" id="xshidden"><img src="../../assets/img/dedicated/icon5.png" class="img-responsive wow zoomInLeft"></td>
            <td class="center">Quad Core</td>
            <td class="center">4 TB</td>
            <td class="center">4 GB</td>
            <td class="center">$99.99 p/m</td>
            <td class="center"><a href="cart.php" class="btn btn-lg btn-main" role="button"><i class="fa fa-server"></i>Configure</a></td>
          </tr>
          <!--End Plan-->
        </table>
      </div>
      <!--End Plans Table-->
    </div>
  </div>
</section>
<!--End Dedicated Servers-->
<!--Begin Dedicated Tables-->
<section id="dedicatedtable">
  <div class="container">
    <h3 class="intro wow fadeInLeft">Dual Core Servers:</h3>
    <p class="light wow fadeInLeft">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae consectetur adipiscing elit. Sed vitae iaculis sapien. Phasellus ultrices gravida massa luctus ornare.</p>
    <br />
    <!--Begin Table-->
    <div class="table-responsive wow zoomInDown">
      <table class="table table-striped">
        <tr>
          <th width="25%">Server</th>
          <th width="10%" class="center">CPU</th>
          <th width="10%" class="center">Memory</th>
          <th width="10%" class="center">Bandwidth</th>
          <th width="10%" class="center">Port Speed</th>
          <th width="10%" class="center">Price</th>
          <th width="10%" class="center">Setup</th>
          <th width="15%" class="center">Order</th>
        </tr>
        <tr>
          <td><i class="fa fa-arrow-circle-right"></i>Intel Atom D525</td>
          <td class="center">4 x 1.8 ghz</td>
          <td class="center">8GB DDR3</td>
          <td class="center">10 TB</td>
          <td class="center">1 Gbps</td>
          <td class="center">$79.99 p/m</td>
          <td class="center">24 hours</td>
          <td class="center"><a href="cart.php" class="btn btn-sm btn-blue" role="button">Order Today</a></td>
        </tr>
      </table>
    </div>
    <!--End Table-->
    <h3 class="intro wow fadeInLeft">Quad Core Servers:</h3>
    <p class="light wow fadeInLeft">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae consectetur adipiscing elit. Sed vitae iaculis sapien. Phasellus ultrices gravida massa luctus ornare.</p>
    <br />
    <!--Begin Table-->
    <div class="table-responsive wow zoomInDown">
      <table class="table table-striped">
        <tr>
          <th>Server</th>
          <th class="center">CPU</th>
          <th class="center">Memory</th>
          <th class="center">Bandwidth</th>
          <th class="center">Port Speed</th>
          <th class="center">Price</th>
          <th class="center">Setup</th>
          <th class="center">Order</th>
        </tr>
        <tr>
          <td><i class="fa fa-arrow-circle-right"></i>Intel Core i5 4570</td>
          <td class="center">4 x 3.2 ghz</td>
          <td class="center">8GB - 32GB</td>
          <td class="center">10 TB</td>
          <td class="center">1 Gbps</td>
          <td class="center">$79.99 p/m</td>
          <td class="center">48 hours</td>
          <td class="center"><a href="cart.php" class="btn btn-sm btn-blue" role="button">Order Today</a></td>
        </tr>
        <tr>
          <td><i class="fa fa-arrow-circle-right"></i>Intel Core i7 4770</td>
          <td class="center">8 x 3.4 ghz</td>
          <td class="center">8GB - 32GB</td>
          <td class="center">10 TB</td>
          <td class="center">1 Gbps</td>
          <td class="center">$84.99 p/m</td>
          <td class="center">48 hours</td>
          <td class="center"><a href="cart.php" class="btn btn-sm btn-blue" role="button">Order Today</a></td>
        </tr>
        <tr>
          <td><i class="fa fa-arrow-circle-right"></i>Intel Core i7 4790</td>
          <td class="center">8 x 3.6 ghz</td>
          <td class="center">8GB - 32GB</td>
          <td class="center">10 TB</td>
          <td class="center">1 Gbps</td>
          <td class="center">$89.99 p/m</td>
          <td class="center">48 hours</td>
          <td class="center"><a href="cart.php" class="btn btn-sm btn-blue" role="button">Order Today</a></td>
        </tr>
        <tr>
          <td><i class="fa fa-arrow-circle-right"></i>Int. Xeon E3-1230v3</td>
          <td class="center">8 x 3.3 ghz</td>
          <td class="center">8GB - 32GB</td>
          <td class="center">10 TB</td>
          <td class="center">1 Gbps</td>
          <td class="center">$99.99 p/m</td>
          <td class="center">48 hours</td>
          <td class="center"><a href="cart.php" class="btn btn-sm btn-blue" role="button">Order Today</a></td>
        </tr>
      </table>
    </div>
    <!--End Table-->

  </div>
</section>
<!--End Dedicated Table-->
    </section>
</template>

<script>
    export default {
            name: 'dedicatedHosting'
    }
</script>
