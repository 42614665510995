<template>
    <main id="about_us">
        
    </main>
</template>

<script>
    export default {
            name: 'aboutUs'
    }
</script>