<template>
    <section id="announcementsfeed" class="mb-4 xs:hidden">
        <div class="container bg-grey-darker mx-auto wrapper-border px-4 py-4 text-center text-white">
            <div class="w-2/3 inline-block">
                <div id="announcementsnewsfeed" class="text-left">
                    <template v-if="announcements.length > 0">
                        <ul>
                            <li v-for="(announcement,index) in announcements">
                                <h5><i class="fa fa-commenting-o"></i><a :href="announcement.url">{{announcement.title}}</a></h5>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <h5><i class="fa fa-commenting-o"></i> No Announcements were made!</h5>
                    </template>
                </div>
            </div><div class="w-1/3 inline-block text-right">
                <a href="/#/announcements"><i class="fa fa-calendar"></i> View all Announcements</a>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'announcement',
        created()
        {
            this.$store.dispatch('announcements/grab')
        },
        computed: {
            announcements()
            {
              return this.$store.getters['announcements/getAll'];
            }
        }
    }
</script>
