export default {
    items: [
        {
            name: 'Cart',
            url: '/client/cart/view'
        },
        {
          name: 'Support',
          url: '/client/support',
          children: [
            {
              name: 'Tickets',
              url: '/client/support/tickets'
            }
          ]
        },
        {
            name: 'invoices',
            url: '/client/invoices'
        }
    ]
}
