<template>
  <div id='SSDVPSHosting' class="w-full page-header">
    <h1 class="text-gold text-center py-2">SSD VPS Hosting</h1>
  </div>
</template>

<script>
    export default {
            name: 'ssdVpsHosting'
    }
</script>
