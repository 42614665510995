<template>
  <section id='vpsHosting'>
    <template v-if="mainPath == '/products/vps-hosting'">
        <div class="w-full page-header">
          <h1 class="text-gold text-center py-2">VPS Hosting</h1>
        </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </section>
</template>

<script>
  export default {
    name: 'vpsHosting',
    computed: {
      mainPath() {
        return this.$route.path
      }
    }
  }
</script>
