var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "contact-form" } }, [
    _c("div", { staticClass: "flex mt-10" }, [
      _c("div", { staticClass: "flex-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "wrapper-border p-2 m-2 text-center" }, [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c("fieldset", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name"
                    }
                  ],
                  attrs: { type: "text", name: "name", placeholder: "Name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "name", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("fieldset", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email"
                    }
                  ],
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: "E-mail"
                  },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "email", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("fieldset", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.message,
                      expression: "form.message"
                    }
                  ],
                  attrs: {
                    rows: "6",
                    cols: "30",
                    placeholder: "Type your message here..."
                  },
                  domProps: { value: _vm.form.message },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "message", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm._m(1)
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-1" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header wrapper-border m-2 p-2" }, [
      _c("h2", { staticClass: "text-white text-center" }, [
        _vm._v("Contact Form")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("fieldset", [
      _c(
        "button",
        {
          staticClass:
            "bg-blue hover:bg-blue-dark text-black font-bold py-2 px-4 rounded",
          attrs: { type: "submit" }
        },
        [_vm._v("Send")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }