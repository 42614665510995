<template>
    <main id="home">
        <Slider />
        <Featured />
        <Announcement />
        <DomainChecker />
        <WebPanel />
        <WebTools />
        <TextBoxes />
    </main>
</template>
<script>
    import Vue from 'vue';

    import Announcement from '../components/Announcement';
    import DomainChecker from '../components/DomainChecker';
    import WebPanel from '../components/home/WebPanel';
    import Slider from '../components/home/Slider';
    import Featured from '../components/home/Featured';
    import WebTools from '../components/home/WebTools';
    import TextBoxes from '../components/home/TextBoxes'

    export default {
        name: 'home',
        components: {
            Announcement,
            DomainChecker,
            WebPanel,
            WebTools,
            Slider,
            Featured,
            TextBoxes
        }
    }
</script>
