var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.children.length > 0
    ? _c(
        "li",
        { class: [_vm.dropdown_trigger, _vm.liClass] },
        [
          _vm.url
            ? [
                _c(
                  "router-link",
                  { staticClass: "text-white", attrs: { to: _vm.url } },
                  [
                    _vm.icon ? [_c("i", { class: _vm.icon })] : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.name) + "\n    ")
                  ],
                  2
                )
              ]
            : [
                _vm.icon ? [_c("i", { class: _vm.icon })] : _vm._e(),
                _vm._v(" " + _vm._s(_vm.name) + "\n  ")
              ],
          _vm._v(" "),
          _c("nav-dropdown", { attrs: { children: _vm.children } })
        ],
        2
      )
    : _c(
        "li",
        { class: _vm.liClass },
        [
          _c(
            "router-link",
            { staticClass: "text-white", attrs: { to: _vm.url } },
            [
              _vm.icon ? [_c("i", { class: _vm.icon })] : _vm._e(),
              _vm._v(" " + _vm._s(_vm.name) + "\n  ")
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }