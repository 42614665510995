var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "tickets" } },
    _vm._l(_vm.tickets, function(ticket) {
      return _c("div", [_vm._v("\n    " + _vm._s(ticket.name) + "\n  ")])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }