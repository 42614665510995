var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "container mx-auto mt-8", attrs: { id: "featured" } },
      [
        _c(
          "h1",
          {
            staticClass: "text-gold wrapper-border text-center wow slideInLeft",
            attrs: { "data-wow-delay": "1s" }
          },
          [_vm._v("Featured Product")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/3 inline-block" }, [
          _c(
            "div",
            { staticClass: " text-center wrapper-border p-4 my-4 mr-4" },
            [
              _c("img", {
                staticClass: "top-icon",
                attrs: {
                  src: require("../../assets/img/shared-hosting.png"),
                  alt: "Shared Hosting"
                }
              }),
              _vm._v(" "),
              _c("h3", [_vm._v("Shared Hosting")]),
              _vm._v(" "),
              _c("div", { staticClass: "details text-white" }, [
                _c("span", { staticClass: "price block" }, [
                  _c("sup", [_vm._v("$")]),
                  _vm._v("7"),
                  _c("small", [_vm._v(".79")])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "package" }, [_vm._v("Basic")]),
                _vm._v(" "),
                _c("ul", { staticClass: "specs" }, [
                  _c("li", [
                    _c("i", { staticClass: "fa fa-caret-right" }),
                    _vm._v(" 25GB HD Space")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("i", { staticClass: "fa fa-caret-right" }),
                    _vm._v(" Unlimited Bandwith")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("i", { staticClass: "fa fa-caret-right" }),
                    _vm._v(" Unlimited e-mail accounts")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "more", attrs: { href: "/#/shared-hosting" } },
                [
                  _c(
                    "button",
                    { staticClass: "px-4 py-2 bg-gold-dark rounded-full" },
                    [_vm._v("See More")]
                  )
                ]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/3 inline-block" }, [
          _c("div", { staticClass: "text-center wrapper-border p-4 my-4" }, [
            _c("img", {
              staticClass: "top-icon",
              attrs: {
                src: require("../../assets/img/vps_banner_img.png"),
                alt: "VPS Hosting"
              }
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("VPS Hosting")]),
            _vm._v(" "),
            _c("div", { staticClass: "details text-white" }, [
              _c("span", { staticClass: "price block" }, [
                _c("sup", [_vm._v("$")]),
                _vm._v("7"),
                _c("small", [_vm._v(".49")])
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "package" }, [_vm._v("SSD 1")]),
              _vm._v(" "),
              _c("ul", { staticClass: "specs" }, [
                _c("li", [
                  _c("i", { staticClass: "fa fa-caret-right" }),
                  _vm._v(" 1x 2.4GHz vCore")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("i", { staticClass: "fa fa-caret-right" }),
                  _vm._v(" 2GB RAM")
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("i", { staticClass: "fa fa-caret-right" }),
                  _vm._v(" 20GB SSD")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "more", attrs: { href: "/#/vps" } }, [
              _c(
                "button",
                { staticClass: "px-4 py-2 bg-gold-dark rounded-full" },
                [_vm._v("See More")]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/3 inline-block" }, [
          _c(
            "div",
            { staticClass: "text-center wrapper-border p-4 my-4 ml-4" },
            [
              _c("img", {
                staticClass: "top-icon",
                attrs: {
                  src: require("../../assets/img/reseller.png"),
                  alt: ""
                }
              }),
              _vm._v(" "),
              _c("h3", [_vm._v("Reseller Hosting")]),
              _vm._v(" "),
              _c("div", { staticClass: "details text-white" }, [
                _c("span", { staticClass: "price block" }, [
                  _c("sup", [_vm._v("$")]),
                  _vm._v("25"),
                  _c("small", [_vm._v(".99")])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "package" }, [_vm._v("Beginner")]),
                _vm._v(" "),
                _c("ul", { staticClass: "specs" }, [
                  _c("li", [
                    _c("i", { staticClass: "fa fa-caret-right" }),
                    _vm._v(" 50GB HD Space")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("i", { staticClass: "fa fa-caret-right" }),
                    _vm._v(" 250GB Bandwith")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("i", { staticClass: "fa fa-caret-right" }),
                    _vm._v(" Unlimited cPanel accounts")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "more", attrs: { href: "/#/reseller" } }, [
                _c(
                  "button",
                  { staticClass: "px-4 py-2 bg-gold-dark rounded-full" },
                  [_vm._v("See More")]
                )
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }