<template>
    <footer class="bg-grey-darkest text-white text-center mt-8">
    <agile id="partners" :arrows="false" :dots="false" :infinite="true" :autoplay="true">
            <!--Begin Partner Row-->
            <div class="slide">
                <div class="container mx-auto">
                    <div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="https://www.centos.org/" target="_blank"><img src="../assets/img/partners/centos2.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1">
                        <a href="https://cloudlinux.com/" target="_blank"><img src="../assets/img/partners/cloudlinux-lg2.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1">
                        <a href="http://www.cpanel.net" target="_blank"><img src="../assets/img/partners/cpanel.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1">
                        <a href="https://www.litespeedtech.com" target="_blank"><img src="../assets/img/partners/litespeed.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1">
                        <a href="http://www.microsoft.com" target="_blank"><img src="../assets/img/partners/microsoft.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/4 sm:w-1/3 inline-block px-1">
                        <a href="http://www.whmcs.com" target="_blank"><img src="../assets/img/partners/whmcs.png" alt="Image" class="align-middle"></a>
                    </div>
                </div>

            </div>
            <!--End Partner Row-->
            <!--Begin Partner Row-->
            <div class="slide">
                <div class="container mx-auto">
                    <div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="http://www.cpanel.net" target="_blank"><img src="../assets/img/partners/cpanel.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="http://www.whmcs.com" target="_blank"><img src="../assets/img/partners/whmcs.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="http://www.microsoft.com" target="_blank"><img src="../assets/img/partners/microsoft.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="http://www.parallels.com" target="_blank"><img src="../assets/img/partners/plesk.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="http://www.cloudflare.com" target="_blank"><img src="../assets/img/partners/cloudflare.png" alt="Image" class="align-middle"></a>
                    </div><div class="lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1">
                        <a href="http://www.zumada.com" target="_blank"><img src="../assets/img/partners/zumada.png" alt="Image" class="align-middle"></a>
                    </div>
                </div>
            </div>
            <!--End Partner Row-->
        </agile>
        <div class='container mx-auto'>
            <div class="lg:w-1/4 md:1/2 sm:w-full inline-block align-top">
                <img class="py-4 logo" src="../assets/img/wlogo.png" />
                <div id="copyright" class="py-2">&copy; 2017 Raytech Hosting, All Rights Reserved.</div>
            </div><div class="lg:w-1/4 md:1/2 sm:w-full inline-block align-top">
                <h5 class="py-2">Hosting</h5>
                <ul class="text-left">
                    <li><router-link to="/products/website-hosting">Websites Hosting Plans</router-link></li>
                    <li><router-link to="/products/reseller-hosting">Reseller plans</router-link></li>
                    <li><router-link to="/products/vps-hosting">VPS plans</router-link></li>
                    <!--<li><router-link to="/products/dedicated-hosting">Dedicated Servers</router-link></li>-->
                    <li><router-link to="/products/ssl-certificate">SSL Certificates</router-link></li>
                </ul>
            </div><div class="lg:w-1/4 md:1/2 sm:w-full inline-block align-top">
                <h5 class="py-2">Services</h5>
                <ul class="text-left">

                    <li><router-link to="/services/website-design">Website Design</router-link></li>
                    <li><router-link to="/services/website-services">Websites services</router-link></li>
                    <li><router-link to="/contact-form">Request a custom quote</router-link></li>
                </ul>
            </div><div class="lg:w-1/4 md:1/2 sm:w-full inline-block align-top">
                <h5 class="py-2">Legal</h5>
                <ul class="text-left">
                    <li><router-link to="/legal/privacy-policy">Privacy Policy</router-link></li>
                    <li><router-link to="/legal/sla-agreement">SLA Agreement</router-link></li>
                    <li><router-link to="/legal/terms-of-service">Terms of service</router-link></li>
                    <li><router-link to="/contact-form">Copyright Infringement</router-link></li>
                    <li><router-link to="/contact-form">Request D.C.M.A Takedown</router-link></li>
                </ul>
            </div>
        </div>

    </footer>
</template>

<script>
    import Vue from 'vue';
    import VueAgile from 'vue-agile';
    Vue.use(VueAgile);

    export default {
            name: 'appFooter'
    }
</script>
