var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-full page-header", attrs: { id: "RAMVPSHosting" } },
      [
        _c("h1", { staticClass: "text-gold text-center py-2" }, [
          _vm._v("RAM VPS Hosting")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }