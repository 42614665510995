import Axios from 'axios'
import Vue from 'vue'

Vue.use(Axios)

export default {
  namespaced: true,
  state: {
    products: []
  },
  mutations: {
    set: (state, data) => {
        state.products = data
      }
  },
  actions: {
    grabAll(context) {
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
          headers: {
              Authorization: context.rootState.config.basicAuth
          },
          params: {
              username: context.rootState.config.username,
              password: context.rootState.config.password,
              accesskey: context.rootState.config.accesskey,
              action: 'GetProducts',
              responsetype: 'json'
          },
          withCredentials: true
      }).then((response) => {
        context.commit('set', response.data.products.product, {root:false})
      })
    }
  },
  getters: {
    getAll: (state) => {
      return state.products
    },
    getById:(state) => (id) => {
      return state.products.filter(function(product) {
          return (product.pid === id) ? product : null
      })[0]
    },
    getByName: (state) => (name) => {
      return state.products.filter(function(product) {
          return (product.name === name) ? product : null
      })[0]
    }
  },
}
