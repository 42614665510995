<template>
    <section id="productfeature" class="">
        <div class="w-full bg-white mx-auto mb-4 p-4 text-center">
            <div class="container mx-auto p-4">
            <div class="lg:w-2/3 md:w-1/2 sm:w-full inline-block wow flipInY align-top text-left">
                <h3 class="text-left"><i class="fa fa-arrow-circle-right text-green"></i> User Friendly Control Panel</h3>
                <p class="text-xl text-left">All of our hosting packages include the industry leading control panel which means it's super easy for you to manage your own website without having to rely on experts!</p>
                <p class="text-xl text-left">We offer a fully functional cPanel demo which will allow you to see how easy it is to use before you place your order.</p>
                <a href="http://x3demoa.cpx3demo.com:2082/login/?user=x3demoa&pass=x3demoa" target="_blank"><button class="bg-green-dark text-white px-4 py-2 rounded"><i class="fa fa-play"></i> Live cPanel Demo</button></a>
            </div><div class="lg:w-1/3 md:w-1/2 sm:w-full inline-block wow flipInY">
                <img src="../../assets/img/cpanel.png" class="img-responsive" alt="cPanel">
            </div>
                </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'web_panel'
    }
</script>
