var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "announcement" } },
    [
      _vm.id != 0
        ? [
            _c("h2", {
              staticClass: "text-center wrapper-border p-4 my-4 mx-4",
              domProps: { innerHTML: _vm._s(_vm.announcement.title) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-full inline-block align-top mx-auto mb-8" },
              [
                _c("div", {
                  staticClass: "text-center wrapper-border p-4 my-2 mx-4",
                  domProps: { innerHTML: _vm._s(_vm.announcement.announcement) }
                })
              ]
            )
          ]
        : [
            _c("div", { staticClass: "container mx-auto mb-8" }, [
              _c(
                "table",
                { staticClass: "w-full wrapper-border p-2" },
                _vm._l(_vm.announcements, function(announcement) {
                  return _c("tr", [
                    _c("td", { staticClass: "p-2 w-1/2" }, [
                      _c("a", { attrs: { href: announcement.url } }, [
                        _vm._v(_vm._s(announcement.title))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", {
                      staticClass: "p-2 w-1/2",
                      domProps: { innerHTML: _vm._s(announcement.date) }
                    })
                  ])
                }),
                0
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }