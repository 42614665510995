<template>
    <section id="client_area">
      <h2 class="text-gold text-center">Client Area</h2>
      <div class="container mx-auto">
        <div class='lg:w-1/3 md:w-1/2 sm:w-full inline-block pr-4'>
          <nav class="wrapper-border rounded w-full text-white sm:hidden md:hidden lg:inline-block xl:inline-block">
              <ClientMenu ulClass="clientarea p-4" liClass="p-none" :navItems="navItems"/>
          </nav>
        </div><router-view></router-view>
      </div>
    </section>
</template>

<script>
    import nav from '../_clientNav'
    import ClientMenu from '../components/Menu'

    export default {
        name: 'clientArea',
        data() {
            return {
                navItems: nav.items
            }
        },
        components: {
            ClientMenu
        },
        created: function() {
          if(this.$store.getters['client/isLogged'] !== true) {
            this.$router.push({name: 'login'})
            this.$router.go(1)
          }
        }
    }
</script>
