<template>
    <section id="website-tools">
        <div class="container mx-auto">
            <div class="w-full wrapper-border text-center wow fadeInUp">
                <h1>Building a website has never been easier</h1>
                <p>All of our web hosting packages come with the most popular website building tools from one click</p>
            </div>
            <!--Begin Item-->
            <div class="md:w-1/2 lg:w-1/4 inline-block align-top">
                <div class=" text-center wrapper-border p-4 my-4 mr-4">
                  <div class="thumbnail wow zoomInDown">
                      <div class="caption"> <img src="../../assets/img/website-tools/wordpress.png" class="tool-icon" alt="...">
                          <h3>Word Press</h3>
                          <h4><small>WordPress is an online, open source website creation tool written in PHP. But in non-geek speak, it's probably the easiest and most powerful blogging and website content management system (or CMS) in existence today.<br /><br /></small></h4>
                          <p class="button"><a href="/#/opening-hours" alt="Opening Hours"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
                      </div>
                  </div>
                </div>
            </div><div class="md:w-1/2 lg:w-1/4 inline-block align-top">
                  <div class=" text-center wrapper-border p-4 my-4 mr-4">
                <div class="thumbnail wow zoomInDown" data-wow-delay="0.3s">
                    <div class="caption"> <img src="../../assets/img/website-tools/opencart.png" class="tool-icon" alt="...">
                        <h3>Opencart</h3>
                        <h4><small>OpenCart is an online store management system. It is PHP-based, using a MySQL database and HTML components. Support is provided for different languages and currencies. OpenCart provides a professional and reliable foundation from which to build a successful online store.</small></h4>
                        <p class="button"><a href="/#/opening-hours" alt="Opening Hours"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
                    </div>
                </div>
              </div>
            </div><div class="md:w-1/2 lg:w-1/4 inline-block align-top">
                  <div class=" text-center wrapper-border p-4 my-4 mr-4">
                <div class="thumbnail wow zoomInDown" data-wow-delay="0.6s">
                    <div class="caption"> <img src="../../assets/img/website-tools/PhpBB.png" class="tool-icon" alt="...">
                        <h3>PhpBB</h3>
                        <h4><small>phpBB is an Internet forum package in the PHP scripting language. The name "phpBB" is an abbreviation of PHP Bulletin Board. Features of phpBB include support for multiple database engines, hierarchical subforums, full-text search, plugins and various notification options.</small></h4>
                        <p class="button"><a href="/#/opening-hours" alt="Opening Hours"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
                    </div>
                </div>
              </div>
            </div><div class="md:w-1/2 lg:w-1/4 inline-block align-top">
                  <div class=" text-center wrapper-border p-4 my-4 mr-4">
                <div class="thumbnail wow zoomInDown" data-wow-delay="0.9s">
                    <div class="caption"> <img src="../../assets/img/website-tools/softaculous.png" class="tool-icon" alt="...">
                        <h3>Softaculous</h3>
                        <h4><small>Softaculous is the leading Auto Installer having 387 great scripts, 1115 PHP Classes and we are still adding more. Softaculous is widely used in the Web Hosting industry and it has helped millions of users install applications by the click of a button.</small></h4>
                        <p class="button"><a href="/#/opening-hours" alt="Opening Hours"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
                    </div>
                </div>
            </div>
          </div>
            <!--End Item-->
        </div>
    </section>
</template>

<script>
    export default {
        name: 'websiteTools'
    }
</script>
