<template>
  <main id="reseller_hosting">
    <section id="page_header">
        <div class="w-full page-header">
            <h1 class="text-gold text-center py-2">Reseller Hosting</h1>
        </div>
    </section>
    <section id="featured">
      <div class="container mx-auto wrapper-border p-4 mb-4">
        <div class="xl:w-2/3 lg:w-2/3 md:w-full sm:w-full inline-block pr-2">
          <h2>Start your very own web hosting business</h2>
          <p>RayTech Hosting is continously looking for motivated business partners
             and individuals with the right drive to increase their monthly
             income exponentially. You will get unlimited capablities to offer
             and resell mobile app development services, web design, hosting,
             and internet marketing services to your customers.</p>
          <!--<p>We provide quality development services with 99% customer retention
             rate and growth rate with an average of 420% per quarter proving
             that Raytech LLC has been providing first-class web development
             services since the beginning.</p>-->
          <p>RayTech Hosting Reseller Programs gives you the ability to offer your
            clientele all the same great services as we do using your own brand
            and company independently. This gives you unlimited capabilities to
            increasing your monthly income significantly.</p>
        </div><div class="xl:w-1/3 lg:w-1/3 md:w-full sm:w-full inline-block pl-2 align-top included">
          <h2>Main Benefits</h2>
          <p><i class="fa fa-check-circle text-green"></i> Fixed Monthly Pricing</p>
          <p><i class="fa fa-check-circle text-green"></i> Unlimited WebSites</p>
          <p><i class="fa fa-check-circle text-green"></i> Unlimited Earning Potential</p>
          <p><i class="fa fa-check-circle text-green"></i> Free Domain on annual plans</p>
          <p><i class="fa fa-check-circle text-green"></i> 99.98% Uptime Guarantee</p>
	        <p><i class="fa fa-check-circle text-green"></i> Up to 650gb/s DDOS Protection</p>
        </div>
      </div>
    </section>
    <section id="plans">
      <div class="container text-center mx-auto">
        <template v-for="product in products">
          <div class="xl:w-1/3 lg:w-1/3 md:w-full sm:w-full inline-block">
            <div class="wrapper-border lg:mr-2 lg:p-4">
               <i class="fa fa-server fa-4x text-white" aria-hidden="true"></i>
               <h3 class="plan">{{ product.name }}</h3>
               <h1><sup>$</sup>{{product.pricing.CAD.monthly }}</h1>
               <div v-html="product.description" ></div>
               <p class="order"><button @click="addToCart($event, product.pid)" class="bg-blue px-4 py-2 rounded"><i class="fa fa-cart-plus"></i> Order Today</button></p>
            </div>
          </div>
        </template>
      </div>
    </section>
    <section id="cPanel">
      <div class="w-full bg-white mt-8">
        <div class="container mx-auto py-8">
          <div class="xl:w-2/3 lg:w-2/3 md:w-full sm:w-full inline-block align-top">
            <h3 class="wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;"> <i class="fa fa-arrow-circle-right text-green"></i> Powered by cPanel</h3>
            <p class="font-light wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;">cPanel is a backend administration interface that makes it easy for you to manage your website</p>
              <div class="xl:w-1/2 lg:w-full md:w-1/2 sm:w-full inline-block">
                <p class="font-light wow zoomInDown" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: zoomInDown;"><i class="fa fa-check-circle text-green"></i> Easy to use, friendly interface</p>
                <p class="font-light wow zoomInDown" data-wow-delay="0.4s" style="visibility: visible; animation-delay: 0.4s; animation-name: zoomInDown;"><i class="fa fa-check-circle text-green"></i> Website building tools included</p>
              </div><div class="xl:w-1/2 lg:w-full md:w-1/2 sm:w-full inline-block">
                <p class="font-light wow zoomInDown" data-wow-delay="0.6s" style="visibility: visible; animation-delay: 0.6s; animation-name: zoomInDown;"><i class="fa fa-check-circle text-green"></i> Create new email accounts easily</p>
                <p class="font-light wow zoomInDown" data-wow-delay="0.8s" style="visibility: visible; animation-delay: 0.8s; animation-name: zoomInDown;"><i class="fa fa-check-circle text-green"></i> Easily create and manage databases</p>
              </div>
          </div><div class="xl:w-1/3 lg:w-1/3 md:w-full sm:w-full inline-block wow bounceIn" style="visibility: visible; animation-name: bounceIn;">
           <img src="../../assets/img/cpanel.png" class="img-responsive">
          </div>
        </div>
      </div>
    </section>
    <Features/>
  </main>
</template>

<script>
  import Features from '../../components/Features'

    export default {
      name: 'resellerhosting',
      computed: {
        products() {
          return [this.$store.getters['products/getByName']('Basic'),this.$store.getters['products/getById'](2),this.$store.getters['products/getById'](3)]
        }
      },
      methods: {
        addToCart: function (event, pid) {
          this.$store.dispatch('cart/add', pid)
        }
      },
      components: {
        Features
      },
      created() {
        this.$store.dispatch('products/grabAll');
      }
    }
</script>
