<template>
  <section id="announcement">
    <template v-if="id != 0">
      <h2 class="text-center wrapper-border p-4 my-4 mx-4" v-html="announcement.title"></h2>
      <div class="w-full inline-block align-top mx-auto mb-8">
        <div class="text-center wrapper-border p-4 my-2 mx-4" v-html="announcement.announcement"></div>
      </div>
    </template>
    <template v-else>
      <div class="container mx-auto mb-8">
        <table class="w-full wrapper-border p-2">
          <tr v-for="announcement in announcements">
            <td class="p-2 w-1/2"><a :href="announcement.url">{{ announcement.title }}</a></td>
            <td class="p-2 w-1/2" v-html="announcement.date"></td>
          </tr>
        </table>
      </div>
    </template>
  </section>
</template>

<script>
    export default {
      name: 'announcementPage',
      data() {
        return {
          id: this.$route.params.id
        }
      },
      computed: {
          announcement() {
            var id = this.id
            var data = this.$store.getters['announcements/getById'](id)
            return data
          },
          announcements() {
            var data = this.$store.getters['announcements/getAll']
            return data
          }
      },
      created() {
        this.$store.dispatch('announcements/grab')
      },
      watch: {
        '$route' (to) {
          this.id = (typeof to.params.id === 'undefined') ? 0 : to.params.id;
          this.$nextTick()
        }
      }
    }
</script>
