import Vue from 'vue'
import Router from 'vue-router'

import Full from '../containers/Full'
import ClientArea from '../containers/ClientArea'
import Cart from '../containers/Cart'
import Support from '../containers/Support'
import Tickets from '../containers/Tickets'
import Legal from '../containers/Legal'

import CreateTicket from '../views/ClientArea/Support/CreateTicket'
import ViewTickets from '../views/ClientArea/Support/ViewTickets'
import ManageAccount from '../views/ClientArea/ManageAccount'
import Invoices from '../views/ClientArea/Invoices'
import CartView from '../views/ClientArea/Cart/View'

import Products from '../views/Products'
import WebsiteHosting from '../views/Products/WebsiteHosting'
import ResellerHosting from '../views/Products/ResellerHosting'
import VPSHosting from '../views/Products/VPSHosting'
import SSDVPSHosting from '../views/Products/VPS/SSD'
import CloudVPSHosting from '../views/Products/VPS/Cloud'
import RAMVPSHosting from '../views/Products/VPS/RAM'
import DedicatedHosting from '../views/Products/DedicatedHosting'

import Services from '../views/Services'
import WebsiteDesign from '../views/Services/WebsiteDesign'
import WebsiteServices from '../views/Services/WebsiteServices'

import Privacy from '../views/Legal/PrivacyPolicy'
import SLAAgreement from '../views/Legal/SLAAgreement'
import TermsService from '../views/Legal/TermsService'
import Announcements from '../views/Announcements'

import Home from '../views/Home'
import Login from '../views/Login'
import Logout from '../views/Logout'
import About from '../views/AboutUs'
import Contact from '../views/ContactForm'
import NotFound from '../views/Errors/NotFound'

Vue.use(Router)

export default new Router({
        mode: 'history',
        routes: [{
                path: '/',
                name: 'Home',
                redirect: '/home',
                component: Full,
                children: [
                    {
                        path: 'home',
                        name: 'home',
                        component: Home
                    },
                    {
                      path: 'products',
                      name: 'products',
                      component: Products,
                      children: [
                        {
                            path: 'website-hosting',
                            name: 'website-hosting',
                            component: WebsiteHosting
                        },
                        {
                            path: 'reseller-hosting',
                            name: 'reseller-hosting',
                            component: ResellerHosting
                        },
                        {
                            path: 'vps-hosting',
                            name: 'vps-hosting',
                            component: VPSHosting,
                            children: [
                              {
                                path: 'ssd',
                                name: 'ssd-vps-hosting',
                                component: SSDVPSHosting
                              },
                              {
                                path: 'cloud',
                                name: 'cloud-vps-hosting',
                                component: CloudVPSHosting
                              },
                              {
                                path: 'ram',
                                name: 'ram-vps-hosting',
                                component: RAMVPSHosting
                              }
                            ]
                        },
                        {
                            path: 'dedicated-hosting',
                            name: 'dedicated-hosting',
                            component: DedicatedHosting
                        }
                      ]
                    },
                    {
                        path: 'services',
                        name: 'services',
                        component: Services,
                        children: [
                          {
                            path: 'website-design',
                            name: 'websitedesign',
                            component: WebsiteDesign
                          },
                          {
                            path: 'website-services',
                            name: 'websiteservices',
                            component: WebsiteServices
                          }
                        ]
                    },
                    {
                        path: 'client',
                        name: 'clientarea',
                        component: ClientArea,
                        meta: { requiresAuth: true },
                        children: [
                          {
                            path: 'manage',
                            name: 'manage',
                            component: ManageAccount,
                            meta: { requiresAuth: true }
                          },
                          {
                            path: 'support',
                            name: 'support',
                            component: Support,
                            meta: { requiresAuth: true },
                            children: [
                              {
                                path: 'tickets',
                                name: 'tickets',
                                component: ViewTickets,
                                meta: { requiresAuth: true },
                                children: [
                                  {
                                    path: 'create',
                                    name: 'createTicket',
                                    component: CreateTicket,
                                    meta: { requiresAuth: true }
                                  },
                                  {
                                    path: 'view',
                                    name: 'viewTickets',
                                    components: ViewTickets,
                                    meta: { requiresAuth: true }
                                  }
                                ]
                              },
                            ]
                          },
                          {
                            path: 'cart',
                            name: 'cart',
                            component: Cart,
                            meta: { requiresAuth: true },
                            children: [
                              {
                                path: 'view',
                                name: 'cartView',
                                component: CartView,
                                meta: { requiresAuth: true }
                              }
                            ]
                          },
                          {
                            path: 'invoices',
                            name: 'invoices',
                            component: Invoices,
                            meta: { requiresAuth: true }
                          }
                        ]
                    },
                    {
                      path: 'announcements/:id?',
                      name: 'announcements',
                      component: Announcements
                    },
                    // Legal
                    {
                      path: 'legal',
                      name: 'legal',
                      component: Legal,
                      children: [
                        {
                          path: 'privacy-policy',
                          name: 'privacyPolicy',
                          component: Privacy
                        },
                        {
                          path: 'sla-agreement',
                          name: 'slaAgreement',
                          component: SLAAgreement
                        },
                        {
                          path: 'terms-of-service',
                          name: 'termsService',
                          component: TermsService
                        }
                      ]
                    },
                    // Other
                    {
                        path: 'login',
                        name: 'login',
                        component: Login
                    },
                    {
                      path: 'logout',
                      name: 'logout',
                      component: Logout,
                      meta: { requiresAuth: true }
                    },
                    {
                        path: '/about-us',
                        name: 'about-us',
                        component: About
                    },
                    {
                        path: '/contact-form',
                        name: 'contact',
                        component: Contact
                    }
                ]
        },
      {
        path: '*',
        component: NotFound
      }
    ]
})
