import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'
import ClientArea from './modules/clientarea'
import Announcements from './modules/announcement'
import Cart from './modules/cart'
import Products from './modules/products'

Vue.use(Vuex);
const vuexSessionStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})
export default new Vuex.Store({
    state: {
      config: {
        apiBaseUrl: 'https://dev.myraytech.net/whmcs/includes/api.php',
        basicAuth: "Basic S2V2aW46UGVwVGltMjAxNA==",
        username: 'VBJI6LPwHdjz8au06awH2D0xmCcrKGvn',
        password: 'exikM0P4xZbvLhaAFZ38HyrJGaEmo17L',
        accesskey: 'MET1a0HRvxZuP1tDjGCeTKQRQIxbyp',
        paymentmethod: 'paypal'
      },
      error: {}
    },
    actions,
    mutations,
    getters,
    modules: {
      announcements: Announcements,
      cart: Cart,
      client: ClientArea,
      products: Products
    },
    plugins: [vuexSessionStorage.plugin]
});
