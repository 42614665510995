var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "domain_checker" } },
    [
      _c(
        "div",
        {
          staticClass:
            "container bg-grey-darker mx-auto mb-4 wrapper-border px-4 py-4 text-center text-white"
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _vm._v("\n            See if your domain is available: "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.domain,
                    expression: "domain"
                  }
                ],
                staticClass: "px-2 py-2",
                attrs: { type: "text", size: "20" },
                domProps: { value: _vm.domain },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.domain = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "bg-blue hover:bg-blue-darker rounded px-4 py-2 font-semibold",
                  attrs: { type: "submit" }
                },
                [_vm._v("Go ")]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { width: "300px", height: "auto", name: "domain_checker" } },
        [
          _c(
            "div",
            { staticClass: "p-2" },
            [
              _vm._v("\n      Domain " + _vm._s(_vm.domain) + " is\n      "),
              _vm.status == "unavailable"
                ? [
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(_vm._s(_vm.status))
                    ])
                  ]
                : [
                    _c("span", { staticClass: "text-green" }, [
                      _vm._v(_vm._s(_vm.status))
                    ])
                  ]
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }