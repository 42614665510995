var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "privacy" } }, [
      _c("h1", { staticClass: "wrapper-border py-4 text-center" }, [
        _vm._v("Privacy Policy")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-border p-4 mt-8" }, [
        _c("h3", { staticClass: "intro" }, [_vm._v("How we use your data")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "In an effort to protect our clients' and site visitors privacy and rights, we have established a Privacy Policy which explains what information we gather on visitors and what we do with information that we gather."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            'This Privacy Policy governs the manner in which we collect, use, maintain and disclose information collected from users of this Web site (each, a "User").'
          )
        ]),
        _vm._v(" "),
        _c("h4", [
          _c("i", { staticClass: "fa fa-angle-double-right" }),
          _vm._v("Privacy")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Users' privacy is very important to us. We are committed to safeguarding the information Users entrust to us."
          )
        ]),
        _vm._v(" "),
        _c("h4", [
          _c("i", { staticClass: "fa fa-angle-double-right" }),
          _vm._v("Data Collection")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "We collect personally identifiable information from Users through online forms for ordering products and services."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            'We may also collect information about how Users use our Web site, for example, by tracking the number of unique views received by the pages of the Web site or the domains from which Users originate. We use "cookies" to track how Users use our Web site.'
          )
        ]),
        _vm._v(" "),
        _c("h4", [
          _c("i", { staticClass: "fa fa-angle-double-right" }),
          _vm._v("Data Usage")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "We may use personally identifiable information collected through our Web site to contact Users regarding products and services offered by us and our trusted affiliates, independent contractors and business partners, and otherwise to enhance Users' experience with us and such affiliates, independent contractors and business partners."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "At no time will our database of users ever be sold to any entity for the purpose of marketing or mailing lists. Personal information will not be sold or otherwise transferred to our business partners without your prior consent, except that we will disclose the information we collect to third parties when, in our good faith judgment, we are obligated to do so under applicable laws."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }