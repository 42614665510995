<template>
    <header class="block bg-grey-darkest text-white sm:block md:block lg:inline-block xl:inline-block">
        <div id="logo" class="block text-white sm:block md:block lg:inline-block xl:inline-block">
            <img class="pl-4 py-2" src="../assets/img/wlogo.png" />
        </div>
        <nav class="hidden text-white sm:hidden md:hidden lg:inline-block xl:inline-block">
            <Menu liClass="py-5 pr-5" :navItems="navItems" />
        </nav>
    </header>
</template>

<script>
    import nav from '../_nav'
    import Menu from './Menu'

    export default {
        name: 'appHeader',
        data() {
            return {
                navItems: nav.items
            }
        },
        components: {
            Menu
        }
    }
</script>
