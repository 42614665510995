<template>
  <section id="tickets">
  <div v-for="ticket in tickets">
    {{ticket.name}}
  </div>
</section>
</template>

<script>
  export default {
    name: 'ticketsView',
    computed: {
      tickets: function() {
        return this.$store.getters['client/tickets']
      }
    },
    created: function() {
      this.$store.dispatch('client/grabTickets')
    }
  }
</script>
