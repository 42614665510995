<template>
  <main id="products">
    <template v-if="mainPath == '/products'">
      <div class="w-full page-header">
        <h1 class="text-gold text-center py-2">Products</h1>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </main>
</template>

<script>
export default {
  name: 'products',
  computed: {
    mainPath() {
      return this.$route.path
    }
  }
}
</script>
