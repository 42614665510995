var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "lg:w-2/3 md:w-1/2 sm:w-full inline-block align-top",
      attrs: { id: "Account" }
    },
    [
      _c("div", { staticClass: "wrapper-border text-center p-4" }, [
        _c("p", [
          _vm._v("Hello " + _vm._s(_vm.user.fullname) + ", "),
          _c("br"),
          _vm._v(
            "Welcome to the RayTech Hosting's new dashboard,\n      feel free to report any bugs thru the ticket system"
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }