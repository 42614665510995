var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { id: "website_hosting" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("section", { attrs: { id: "plans" } }, [
        _c(
          "div",
          { staticClass: "container text-center mx-auto" },
          [
            _vm._l(_vm.products, function(product) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "xl:w-1/3 lg:w-1/3 md:w-full sm:w-full inline-block"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "wrapper-border lg:mr-2 lg:p-4" },
                      [
                        _c("i", {
                          staticClass: "fa fa-server fa-4x text-white",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(" "),
                        _c("h3", { staticClass: "plan" }, [
                          _vm._v(_vm._s(product.name))
                        ]),
                        _vm._v(" "),
                        _c("h1", [
                          _c("sup", [_vm._v("$")]),
                          _vm._v(_vm._s(product.pricing.CAD.monthly))
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(product.description) }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "order" }, [
                          _c(
                            "button",
                            {
                              staticClass: "bg-blue px-4 py-2 rounded",
                              on: {
                                click: function($event) {
                                  return _vm.addToCart($event, product.pid)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-cart-plus" }),
                              _vm._v(" Order Today")
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("features")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "page_header" } }, [
      _c("div", { staticClass: "w-full page-header" }, [
        _c("h1", { staticClass: "text-gold text-center py-2" }, [
          _vm._v("Website Hosting")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "featured" } }, [
      _c("div", { staticClass: "container mx-auto wrapper-border p-4 mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "xl:w-2/3 lg:w-2/3 md:w-full sm:w-full inline-block pr-2"
          },
          [
            _c("h2", [_vm._v("Reliable Website Hosting")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "RayTech Hosting strives to provide the fastest and safest web platform\n           available. All of our servers come standard with advanced firewall\n            protection, redundant communications, and the highest speed\n             available on the market."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Clustered communications and advanced attack mitigations leave the\n          administrators of websites in control, and resting easy knowing their\n           service will remain available - even in the case of a disaster."
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "xl:w-1/3 lg:w-1/3 md:w-full sm:w-full inline-block pl-2 align-top included"
          },
          [
            _c("h2", [_vm._v("Included as Standard:")]),
            _vm._v(" "),
            _c("p", [
              _c("i", { staticClass: "fa fa-check-circle text-green" }),
              _vm._v(" Free Instant Setup")
            ]),
            _vm._v(" "),
            _c("p", [
              _c("i", { staticClass: "fa fa-check-circle text-green" }),
              _vm._v(" Free Website Migration")
            ]),
            _vm._v(" "),
            _c("p", [
              _c("i", { staticClass: "fa fa-check-circle text-green" }),
              _vm._v(" Free Domain on annual plans")
            ]),
            _vm._v(" "),
            _c("p", [
              _c("i", { staticClass: "fa fa-check-circle text-green" }),
              _vm._v(" 99.98% Uptime Guarantee")
            ]),
            _vm._v(" "),
            _c("p", [
              _c("i", { staticClass: "fa fa-check-circle text-green" }),
              _vm._v(" Up to 650gb/s DDOS Protection")
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "cPanel" } }, [
      _c("div", { staticClass: "w-full bg-white mt-8" }, [
        _c("div", { staticClass: "container mx-auto py-8" }, [
          _c(
            "div",
            {
              staticClass:
                "xl:w-2/3 lg:w-2/3 md:w-full sm:w-full inline-block align-top"
            },
            [
              _c(
                "h3",
                {
                  staticClass: "wow fadeInLeft",
                  staticStyle: {
                    visibility: "visible",
                    "animation-name": "fadeInLeft"
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-arrow-circle-right text-green"
                  }),
                  _vm._v(" Powered by cPanel")
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "font-light wow fadeInLeft",
                  staticStyle: {
                    visibility: "visible",
                    "animation-name": "fadeInLeft"
                  }
                },
                [
                  _vm._v(
                    "cPanel is a backend administration interface that makes it easy for you to manage your website"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "xl:w-1/2 lg:w-full md:w-1/2 sm:w-full inline-block"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "font-light wow zoomInDown",
                      staticStyle: {
                        visibility: "visible",
                        "animation-delay": "0.2s",
                        "animation-name": "zoomInDown"
                      },
                      attrs: { "data-wow-delay": "0.2s" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-check-circle text-green" }),
                      _vm._v(" Easy to use, friendly interface")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "font-light wow zoomInDown",
                      staticStyle: {
                        visibility: "visible",
                        "animation-delay": "0.4s",
                        "animation-name": "zoomInDown"
                      },
                      attrs: { "data-wow-delay": "0.4s" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-check-circle text-green" }),
                      _vm._v(" Website building tools included")
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "xl:w-1/2 lg:w-full md:w-1/2 sm:w-full inline-block"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "font-light wow zoomInDown",
                      staticStyle: {
                        visibility: "visible",
                        "animation-delay": "0.6s",
                        "animation-name": "zoomInDown"
                      },
                      attrs: { "data-wow-delay": "0.6s" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-check-circle text-green" }),
                      _vm._v(" Create new email accounts easily")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "font-light wow zoomInDown",
                      staticStyle: {
                        visibility: "visible",
                        "animation-delay": "0.8s",
                        "animation-name": "zoomInDown"
                      },
                      attrs: { "data-wow-delay": "0.8s" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-check-circle text-green" }),
                      _vm._v(" Easily create and manage databases")
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "xl:w-1/3 lg:w-1/3 md:w-full sm:w-full inline-block wow bounceIn",
              staticStyle: {
                visibility: "visible",
                "animation-name": "bounceIn"
              }
            },
            [
              _c("img", {
                staticClass: "img-responsive",
                attrs: { src: require("../../assets/img/cpanel.png") }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }