<template>
      <div id="app">
        <AppHeader/>
            <router-view></router-view>
        <AppFooter/>
      </div>
</template>

<script>
import nav from '../_nav'
import AppHeader from '../components/Header.vue'
import AppFooter from '../components/Footer.vue'


export default {
    name: 'full',
    components: {
        AppHeader,
        AppFooter
    }
}
</script>
