import Axios from 'axios'
import Vue from 'vue'

Vue.use(Axios)

export default {
  namespaced: true,
  state: {
    announcements: []
  },
  mutations: {
    set: (state, announcements) => {
        state.announcements = announcements.announcements;

    }
  },
  actions: {
    grab: (context) => {
        Axios.post(context.rootState.config.apiBaseUrl, {}, {
            headers: {
                Authorization: context.rootState.config.basicAuth
            },
            params: {
                username: context.rootState.config.username,
                password: context.rootState.config.password,
                accesskey: context.rootState.config.accesskey,
                action: 'GetAnnouncements',
                responsetype: 'json'
            },
            withCredentials: true
            }).then((response) => {
              let announcements = response.data.announcements.announcement
              var i
              for(i = 0; i <= (announcements.length - 1); i++) {
                announcements[i].url = "/#/announcements/" + announcements[i].id;
              }
              context.commit('set', {announcements:announcements}, {root:false})
            })
    }
  },
  getters: {
    getAll: (state) => {
        return state.announcements;
    },
    getById:(state) => (id) => {
      return state.announcements.filter(function(announcement) {
          let output = (announcement.id == id) ? announcement : null
          return output
      })[0]
    }
  }

}
