var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "imageslider" } }, [
    _c(
      "div",
      { staticClass: "w-full text-center flex-1" },
      [
        _c(
          "agile",
          {
            attrs: {
              arrows: false,
              dots: false,
              infinite: true,
              autoplay: true
            }
          },
          [
            _c("div", { staticClass: "slide", attrs: { id: "slide1" } }, [
              _c("div", { staticClass: "flex slideroverlay px-12 py-8" }, [
                _c("div", { staticClass: "w-3/5" }, [
                  _c("h1", { staticClass: "holder wow slideInLeft'" }, [
                    _vm._v("Technology Services")
                  ]),
                  _vm._v(" "),
                  _c(
                    "h3",
                    {
                      staticClass: "wow slideInLeft",
                      attrs: { "data-wow-delay": "0.1s" }
                    },
                    [
                      _vm._v(
                        "We specialise is a wide range of technology services to help keep your business online and keep your customers happy"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "features text-left pl-4 ml-6" }, [
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "wow zoomIn",
                          attrs: { "data-wow-delay": "0.2s" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v("Web Hosting")
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "wow zoomIn",
                          attrs: { "data-wow-delay": "0.4s" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v("Domain Names")
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "wow zoomIn",
                          attrs: { "data-wow-delay": "0.6s" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v("SSL Certificates")
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "wow zoomIn",
                          attrs: { "data-wow-delay": "0.5s" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v("Firewall Protection")
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "wow zoomIn",
                          attrs: { "data-wow-delay": "0.8s" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v("Data Security")
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "wow zoomIn",
                          attrs: { "data-wow-delay": "1.0s" }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check-square-o" }),
                          _vm._v("Website Services")
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full" }, [
                    _c("p", { staticClass: "slidebutton" }, [
                      _c("a", { attrs: { href: "/#/website-hosting" } }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "text-white bg-green px-4 py-2 rounded",
                            attrs: { role: "button" }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-arrow-circle-right"
                            }),
                            _vm._v("View Hosting Plans")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "/#/contact" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "text-white bg-blue px-4 py-2 rounded",
                            attrs: { role: "button" }
                          },
                          [
                            _c("i", { staticClass: "fa fa-question-circle" }),
                            _vm._v("Contact our team for advice")
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-2/5 wow pulse md:block sm:hidden" },
                  [
                    _c("img", {
                      staticClass: "img-responsive",
                      attrs: {
                        alt: "Hosting Services",
                        src: require("../../assets/img/slide-image1.png")
                      }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "slide", attrs: { id: "slide2" } }, [
              _c("div", { staticClass: "flex slideroverlay px-12 py-8" }, [
                _c("div", { staticClass: "w-3/5" }, [
                  _c("h1", [_vm._v("Reseller Web Hosting")]),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(
                      "Want to be your own boss? Become a reseller and make money by selling our server space to your own clients!"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "features text-left pl-4 ml-6" }, [
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Fixed Monthly Fee")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Unlimited Clients")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Free Domain Name")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("25% Off WHMCS Licenses")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("No Hidden Fees")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("User Support Included")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full" }, [
                    _c("p", { staticClass: "slidebutton" }, [
                      _c("a", { attrs: { href: "reseller-hosting.php" } }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "text-white bg-green px-4 py-2 rounded",
                            attrs: { role: "button" }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-arrow-circle-right"
                            }),
                            _vm._v("View Reseller Plans")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "reseller-hosting.php" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "text-white bg-blue px-4 py-2 rounded",
                            attrs: { role: "button" }
                          },
                          [
                            _c("i", { staticClass: "fa fa-question-circle" }),
                            _vm._v("What is reseller hosting?")
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/5 md:block sm:hidden" }, [
                  _c("img", {
                    staticClass: "img-responsive",
                    attrs: {
                      alt: "Reseller",
                      src: require("../../assets/img/slide-image2.png")
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "slide", attrs: { id: "slide3" } }, [
              _c("div", { staticClass: "flex slideroverlay px-12 py-8" }, [
                _c("div", { staticClass: "w-3/5" }, [
                  _c("h1", [_vm._v("Dedicated Solutions")]),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(
                      "We have top-end dedicated servers available, ideal for growing websites that need additional resources"
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "features text-left pl-4 ml-6" }, [
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Dedicated Resources")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Fully Managed Server")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Built in Firewall")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Free Control Panel")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("24/7 Support Access")
                      ])
                    ]),
                    _c("div", { staticClass: "w-1/3 inline-block" }, [
                      _c("h4", [
                        _c("i", { staticClass: "fa fa-check-square-o" }),
                        _vm._v("Free Setup / Migration")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-full" }, [
                    _c("p", { staticClass: "slidebutton" }),
                    _c("p", { staticClass: "slidebutton" }, [
                      _c("a", { attrs: { href: "dedicated-servers.php" } }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "text-white bg-green px-4 py-2 rounded",
                            attrs: { role: "button" }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-arrow-circle-right"
                            }),
                            _vm._v("View Dedicated Plans")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "dedicated-servers.php" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "text-white bg-blue px-4 py-2 rounded",
                            attrs: { role: "button" }
                          },
                          [
                            _c("i", { staticClass: "fa fa-question-circle" }),
                            _vm._v("What is dedicated hosting?")
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/5 md:block sm:hidden" }, [
                  _c("img", {
                    staticClass: "img-responsive",
                    attrs: {
                      alt: "Dedicated Servers",
                      src: require("../../assets/img/slide-image3.png")
                    }
                  })
                ])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }