var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "website-tools" } }, [
      _c("div", { staticClass: "container mx-auto" }, [
        _c(
          "div",
          { staticClass: "w-full wrapper-border text-center wow fadeInUp" },
          [
            _c("h1", [_vm._v("Building a website has never been easier")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "All of our web hosting packages come with the most popular website building tools from one click"
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/4 inline-block align-top" }, [
          _c(
            "div",
            { staticClass: " text-center wrapper-border p-4 my-4 mr-4" },
            [
              _c("div", { staticClass: "thumbnail wow zoomInDown" }, [
                _c("div", { staticClass: "caption" }, [
                  _c("img", {
                    staticClass: "tool-icon",
                    attrs: {
                      src: require("../../assets/img/website-tools/wordpress.png"),
                      alt: "..."
                    }
                  }),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Word Press")]),
                  _vm._v(" "),
                  _c("h4", [
                    _c("small", [
                      _vm._v(
                        "WordPress is an online, open source website creation tool written in PHP. But in non-geek speak, it's probably the easiest and most powerful blogging and website content management system (or CMS) in existence today."
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "button" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/#/opening-hours",
                          alt: "Opening Hours"
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "bg-gold-dark px-4 py-2 rounded-full mt-2"
                          },
                          [
                            _c("i", { staticClass: "fa fa-question-circle" }),
                            _vm._v(" Learn More")
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/4 inline-block align-top" }, [
          _c(
            "div",
            { staticClass: " text-center wrapper-border p-4 my-4 mr-4" },
            [
              _c(
                "div",
                {
                  staticClass: "thumbnail wow zoomInDown",
                  attrs: { "data-wow-delay": "0.3s" }
                },
                [
                  _c("div", { staticClass: "caption" }, [
                    _c("img", {
                      staticClass: "tool-icon",
                      attrs: {
                        src: require("../../assets/img/website-tools/opencart.png"),
                        alt: "..."
                      }
                    }),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Opencart")]),
                    _vm._v(" "),
                    _c("h4", [
                      _c("small", [
                        _vm._v(
                          "OpenCart is an online store management system. It is PHP-based, using a MySQL database and HTML components. Support is provided for different languages and currencies. OpenCart provides a professional and reliable foundation from which to build a successful online store."
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/#/opening-hours",
                            alt: "Opening Hours"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "bg-gold-dark px-4 py-2 rounded-full mt-2"
                            },
                            [
                              _c("i", { staticClass: "fa fa-question-circle" }),
                              _vm._v(" Learn More")
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/4 inline-block align-top" }, [
          _c(
            "div",
            { staticClass: " text-center wrapper-border p-4 my-4 mr-4" },
            [
              _c(
                "div",
                {
                  staticClass: "thumbnail wow zoomInDown",
                  attrs: { "data-wow-delay": "0.6s" }
                },
                [
                  _c("div", { staticClass: "caption" }, [
                    _c("img", {
                      staticClass: "tool-icon",
                      attrs: {
                        src: require("../../assets/img/website-tools/PhpBB.png"),
                        alt: "..."
                      }
                    }),
                    _vm._v(" "),
                    _c("h3", [_vm._v("PhpBB")]),
                    _vm._v(" "),
                    _c("h4", [
                      _c("small", [
                        _vm._v(
                          'phpBB is an Internet forum package in the PHP scripting language. The name "phpBB" is an abbreviation of PHP Bulletin Board. Features of phpBB include support for multiple database engines, hierarchical subforums, full-text search, plugins and various notification options.'
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/#/opening-hours",
                            alt: "Opening Hours"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "bg-gold-dark px-4 py-2 rounded-full mt-2"
                            },
                            [
                              _c("i", { staticClass: "fa fa-question-circle" }),
                              _vm._v(" Learn More")
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "md:w-1/2 lg:w-1/4 inline-block align-top" }, [
          _c(
            "div",
            { staticClass: " text-center wrapper-border p-4 my-4 mr-4" },
            [
              _c(
                "div",
                {
                  staticClass: "thumbnail wow zoomInDown",
                  attrs: { "data-wow-delay": "0.9s" }
                },
                [
                  _c("div", { staticClass: "caption" }, [
                    _c("img", {
                      staticClass: "tool-icon",
                      attrs: {
                        src: require("../../assets/img/website-tools/softaculous.png"),
                        alt: "..."
                      }
                    }),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Softaculous")]),
                    _vm._v(" "),
                    _c("h4", [
                      _c("small", [
                        _vm._v(
                          "Softaculous is the leading Auto Installer having 387 great scripts, 1115 PHP Classes and we are still adding more. Softaculous is widely used in the Web Hosting industry and it has helped millions of users install applications by the click of a button."
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "button" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/#/opening-hours",
                            alt: "Opening Hours"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "bg-gold-dark px-4 py-2 rounded-full mt-2"
                            },
                            [
                              _c("i", { staticClass: "fa fa-question-circle" }),
                              _vm._v(" Learn More")
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }