<template>
    <section id="Account" class="lg:w-2/3 md:w-1/2 sm:w-full inline-block align-top">
      <div class="wrapper-border text-center p-4">
        <p>Hello {{user.fullname}}, <br />Welcome to the RayTech Hosting's new dashboard,
          feel free to report any bugs thru the ticket system</p>
        </div>
    </section>
</template>

<script>
  export default {
    name: 'manageAccount',
    computed: {
      user: function() {
        return this.$store.getters['client/details']
      }
    }
  }
</script>
