var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "productfeature" } }, [
      _c(
        "div",
        { staticClass: "w-full bg-white mx-auto mb-4 p-4 text-center" },
        [
          _c("div", { staticClass: "container mx-auto p-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "lg:w-2/3 md:w-1/2 sm:w-full inline-block wow flipInY align-top text-left"
              },
              [
                _c("h3", { staticClass: "text-left" }, [
                  _c("i", {
                    staticClass: "fa fa-arrow-circle-right text-green"
                  }),
                  _vm._v(" User Friendly Control Panel")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-xl text-left" }, [
                  _vm._v(
                    "All of our hosting packages include the industry leading control panel which means it's super easy for you to manage your own website without having to rely on experts!"
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-xl text-left" }, [
                  _vm._v(
                    "We offer a fully functional cPanel demo which will allow you to see how easy it is to use before you place your order."
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "http://x3demoa.cpx3demo.com:2082/login/?user=x3demoa&pass=x3demoa",
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "bg-green-dark text-white px-4 py-2 rounded"
                      },
                      [
                        _c("i", { staticClass: "fa fa-play" }),
                        _vm._v(" Live cPanel Demo")
                      ]
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "lg:w-1/3 md:w-1/2 sm:w-full inline-block wow flipInY"
              },
              [
                _c("img", {
                  staticClass: "img-responsive",
                  attrs: {
                    src: require("../../assets/img/cpanel.png"),
                    alt: "cPanel"
                  }
                })
              ]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }