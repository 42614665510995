var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "hostingfeatures" } }, [
    _c(
      "div",
      { staticClass: "container mx-auto" },
      [
        _c(
          "h3",
          {
            staticClass:
              "wrapper-border p-2 my-4 text-center text-gold wow fadeInLeft"
          },
          [_vm._v("Great features included with all plans")]
        ),
        _vm._v(" "),
        _vm._l(_vm.features, function(feature) {
          return [
            _c(
              "div",
              {
                staticClass:
                  "xl:w-1/3 lg:w-1/3 md:w-1/3 sm:w-full md:h-half mb-3 inline-block align-top feature wow zoomInDown",
                attrs: { "data-wow-delay": feature.wowdelay }
              },
              [
                _c(
                  "div",
                  { staticClass: "wrapper-border p-4 h-full text-center m-4" },
                  [
                    _c("img", {
                      staticClass: "img-responsive",
                      attrs: { src: feature.img }
                    }),
                    _vm._v(" "),
                    _c("h4", [_vm._v(_vm._s(feature.title))]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font-light" }, [
                      _vm._v(_vm._s(feature.text))
                    ])
                  ]
                )
              ]
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }