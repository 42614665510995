var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { id: "products" } },
    [_vm.mainPath == "/services" ? [_vm._m(0)] : [_c("router-view")]],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full page-header" }, [
      _c("h1", { staticClass: "text-gold text-center py-2" }, [
        _vm._v("Services")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }