<template>
  <div id="app">
    <AppHeader/>
    <div class="wrapper-border text-center text-2xl p-4 mt-8">404 Page Not Found</div>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from '../../components/Header.vue'
import AppFooter from '../../components/Footer.vue'

  export default {
    name: 'NotFound',
    components: {
        AppHeader,
        AppFooter
    }
  }
</script>
