var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "bg-grey-darkest text-white text-center mt-8" },
    [
      _c(
        "agile",
        {
          attrs: {
            id: "partners",
            arrows: false,
            dots: false,
            infinite: true,
            autoplay: true
          }
        },
        [
          _c("div", { staticClass: "slide" }, [
            _c("div", { staticClass: "container mx-auto" }, [
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.centos.org/",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/centos2.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://cloudlinux.com/",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/cloudlinux-lg2.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "http://www.cpanel.net", target: "_blank" }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/cpanel.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.litespeedtech.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/litespeed.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/4 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.microsoft.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/microsoft.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/4 sm:w-1/3 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "http://www.whmcs.com", target: "_blank" }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/whmcs.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "slide" }, [
            _c("div", { staticClass: "container mx-auto" }, [
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "http://www.cpanel.net", target: "_blank" }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/cpanel.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "http://www.whmcs.com", target: "_blank" }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/whmcs.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.microsoft.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/microsoft.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.parallels.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/plesk.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.cloudflare.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/cloudflare.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:w-1/6 md:w-1/6 sm:w-1/6 inline-block px-1" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "http://www.zumada.com", target: "_blank" }
                    },
                    [
                      _c("img", {
                        staticClass: "align-middle",
                        attrs: {
                          src: require("../assets/img/partners/zumada.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container mx-auto" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "lg:w-1/4 md:1/2 sm:w-full inline-block align-top" },
          [
            _c("h5", { staticClass: "py-2" }, [_vm._v("Hosting")]),
            _vm._v(" "),
            _c("ul", { staticClass: "text-left" }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/products/website-hosting" } },
                    [_vm._v("Websites Hosting Plans")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/products/reseller-hosting" } },
                    [_vm._v("Reseller plans")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/products/vps-hosting" } },
                    [_vm._v("VPS plans")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/products/ssl-certificate" } },
                    [_vm._v("SSL Certificates")]
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "lg:w-1/4 md:1/2 sm:w-full inline-block align-top" },
          [
            _c("h5", { staticClass: "py-2" }, [_vm._v("Services")]),
            _vm._v(" "),
            _c("ul", { staticClass: "text-left" }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/services/website-design" } },
                    [_vm._v("Website Design")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/services/website-services" } },
                    [_vm._v("Websites services")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/contact-form" } }, [
                    _vm._v("Request a custom quote")
                  ])
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "lg:w-1/4 md:1/2 sm:w-full inline-block align-top" },
          [
            _c("h5", { staticClass: "py-2" }, [_vm._v("Legal")]),
            _vm._v(" "),
            _c("ul", { staticClass: "text-left" }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/legal/privacy-policy" } },
                    [_vm._v("Privacy Policy")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/legal/sla-agreement" } }, [
                    _vm._v("SLA Agreement")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/legal/terms-of-service" } },
                    [_vm._v("Terms of service")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/contact-form" } }, [
                    _vm._v("Copyright Infringement")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/contact-form" } }, [
                    _vm._v("Request D.C.M.A Takedown")
                  ])
                ],
                1
              )
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "lg:w-1/4 md:1/2 sm:w-full inline-block align-top" },
      [
        _c("img", {
          staticClass: "py-4 logo",
          attrs: { src: require("../assets/img/wlogo.png") }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "py-2", attrs: { id: "copyright" } }, [
          _vm._v("© 2017 Raytech Hosting, All Rights Reserved.")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }