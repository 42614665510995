<template>
    <section id="imageslider">
        <div class="w-full text-center flex-1">
            <agile :arrows="false" :dots="false" :infinite="true" :autoplay="true">
                <div id="slide1" class="slide">
                    <div class="flex slideroverlay px-12 py-8">
                        <div class="w-3/5">
                            <h1  class="holder wow slideInLeft'">Technology Services</h1>
                            <h3 class="wow slideInLeft" data-wow-delay="0.1s">We specialise is a wide range of technology services to help keep your business online and keep your customers happy</h3>
                            <div class="features text-left pl-4 ml-6">
                                <div class="w-1/3 inline-block">
                                    <h4 class="wow zoomIn" data-wow-delay="0.2s"><i class="fa fa-check-square-o"></i>Web Hosting</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4 class="wow zoomIn" data-wow-delay="0.4s"><i class="fa fa-check-square-o"></i>Domain Names</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4 class="wow zoomIn" data-wow-delay="0.6s"><i class="fa fa-check-square-o"></i>SSL Certificates</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4 class="wow zoomIn" data-wow-delay="0.5s"><i class="fa fa-check-square-o"></i>Firewall Protection</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4 class="wow zoomIn" data-wow-delay="0.8s"><i class="fa fa-check-square-o"></i>Data Security</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4 class="wow zoomIn" data-wow-delay="1.0s"><i class="fa fa-check-square-o"></i>Website Services</h4>
                                </div>
                            </div>
                            <div class="w-full">
                                <p class="slidebutton">
                                    <a href="/#/website-hosting"><button class="text-white bg-green px-4 py-2 rounded" role="button"><i class="fa fa-arrow-circle-right"></i>View Hosting Plans</button></a>
                                    <a href="/#/contact"><button class="text-white bg-blue px-4 py-2 rounded" role="button"><i class="fa fa-question-circle"></i>Contact our team for advice</button></a>
                                </p>
                            </div>
                        </div>
                        <div class="w-2/5 wow pulse md:block sm:hidden"> <img alt="Hosting Services" src="../../assets/img/slide-image1.png" class="img-responsive" /> </div>
                    </div>
                </div>
                <div id="slide2" class="slide">
                    <div class="flex slideroverlay px-12 py-8">
                        <div class="w-3/5">
                            <h1>Reseller Web Hosting</h1>
                            <h3>Want to be your own boss? Become a reseller and make money by selling our server space to your own clients!</h3>
                            <div class="features text-left pl-4 ml-6">
                                <div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Fixed Monthly Fee</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Unlimited Clients</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Free Domain Name</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>25% Off WHMCS Licenses</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>No Hidden Fees</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>User Support Included</h4>
                                </div>
                            </div>
                            <div class="w-full">
                                <p class="slidebutton">
                                    <a href="reseller-hosting.php"> <button class="text-white bg-green px-4 py-2 rounded" role="button"><i class="fa fa-arrow-circle-right"></i>View Reseller Plans</button></a>
                                    <a href="reseller-hosting.php"><button class="text-white bg-blue px-4 py-2 rounded" role="button"><i class="fa fa-question-circle"></i>What is reseller hosting?</button></a>
                                </p>
                            </div>
                        </div>
                        <div class="w-2/5 md:block sm:hidden"> <img alt="Reseller" src="../../assets/img/slide-image2.png" class="img-responsive" /> </div>
                    </div>
                </div>
                <div id="slide3" class="slide">
                    <div class="flex slideroverlay px-12 py-8">
                        <div class="w-3/5">
                            <h1>Dedicated Solutions</h1>
                            <h3>We have top-end dedicated servers available, ideal for growing websites that need additional resources</h3>
                            <div class="features text-left pl-4 ml-6">
                                <div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Dedicated Resources</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Fully Managed Server</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Built in Firewall</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Free Control Panel</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>24/7 Support Access</h4>
                                </div><div class="w-1/3 inline-block">
                                    <h4><i class="fa fa-check-square-o"></i>Free Setup / Migration</h4>
                                </div>
                            </div>
                            <div class="w-full">
                                <p class="slidebutton">
                                <p class="slidebutton">
                                    <a href="dedicated-servers.php"><button class="text-white bg-green px-4 py-2 rounded" role="button"><i class="fa fa-arrow-circle-right"></i>View Dedicated Plans</button></a>
                                    <a href="dedicated-servers.php"><button class="text-white bg-blue px-4 py-2 rounded" role="button"><i class="fa fa-question-circle"></i>What is dedicated hosting?</button></a>
                                </p>
                            </div>
                        </div>

                        <div class="w-2/5 md:block sm:hidden"><img alt="Dedicated Servers" src="../../assets/img/slide-image3.png" class="img-responsive"> </div>
                    </div>
                </div>
            </agile>
        </div>
    </section>
</template>

<script>

    import Vue from 'vue';
    import VueAgile from 'vue-agile';
    
    Vue.use(VueAgile);
    export default {
        name: 'slider'
    }
</script>