var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "textboxes" } }, [
      _c("div", { staticClass: "w-full bg-white mx-auto p-4 text-center" }, [
        _c("div", { staticClass: "container mx-auto p-4" }, [
          _c(
            "div",
            { staticClass: "md:w-1/2 lg:w-1/3 inline-block align-top" },
            [
              _c("div", { staticClass: " text-center p-4 my-4 mr-4" }, [
                _c("h3", [
                  _c("i", { staticClass: "fa fa-server" }),
                  _vm._v("Infrastructure")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "RayTech Hosting has partnered with the global leaders in datacenter technology to provide our customers with the most reliable, robust and completely redundant servers and networking. Join our ranks and take pride knowing your service will be available 24/7/365*"
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "button text-center" }, [
                  _c("a", { attrs: { href: "/#/about-us", alt: "About Us" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "bg-gold-dark px-4 py-2 rounded-full mt-2"
                      },
                      [
                        _c("i", { staticClass: "fa fa-question-circle" }),
                        _vm._v(" Learn More")
                      ]
                    )
                  ])
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "md:w-1/2 lg:w-1/3 inline-block align-top" },
            [
              _c("div", { staticClass: " text-center p-4 my-4 mr-4" }, [
                _c("h3", [
                  _c("i", { staticClass: "fa fa-cloud-upload" }),
                  _vm._v("Uptime Guarantee")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "RayTech Hosting is proud to bring you a 99.98% uptime. No questions - NO BS - IT JUST WORKS!"
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "button text-center align-bottom" }, [
                  _c("a", { attrs: { href: "/#/faqs", alt: "F.A.Q" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "bg-gold-dark px-4 py-2 rounded-full mt-2"
                      },
                      [
                        _c("i", { staticClass: "fa fa-question-circle" }),
                        _vm._v(" Learn More")
                      ]
                    )
                  ])
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "md:w-1/2 lg:w-1/3 inline-block align-top" },
            [
              _c("div", { staticClass: " text-center p-4 my-4 mr-4" }, [
                _c("h3", [
                  _c("i", { staticClass: "fa fa-life-ring" }),
                  _vm._v("Support Center")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "A one stop shop for all service related questions, updated based on the feedback of our great customers with one goal in mind - Bring our experience to a self service help panel. Still having issues? Submit a ticket and have a specialist assist you TODAY!"
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "button text-center align-bottom" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "/#/opening-hours", alt: "Opening Hours" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "bg-gold-dark px-4 py-2 rounded-full mt-2"
                        },
                        [
                          _c("i", { staticClass: "fa fa-question-circle" }),
                          _vm._v(" Learn More")
                        ]
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }