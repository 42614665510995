var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "mb-4 xs:hidden", attrs: { id: "announcementsfeed" } },
    [
      _c(
        "div",
        {
          staticClass:
            "container bg-grey-darker mx-auto wrapper-border px-4 py-4 text-center text-white"
        },
        [
          _c("div", { staticClass: "w-2/3 inline-block" }, [
            _c(
              "div",
              {
                staticClass: "text-left",
                attrs: { id: "announcementsnewsfeed" }
              },
              [
                _vm.announcements.length > 0
                  ? [
                      _c(
                        "ul",
                        _vm._l(_vm.announcements, function(
                          announcement,
                          index
                        ) {
                          return _c("li", [
                            _c("h5", [
                              _c("i", { staticClass: "fa fa-commenting-o" }),
                              _c("a", { attrs: { href: announcement.url } }, [
                                _vm._v(_vm._s(announcement.title))
                              ])
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  : [_vm._m(0)]
              ],
              2
            )
          ]),
          _vm._m(1)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("i", { staticClass: "fa fa-commenting-o" }),
      _vm._v(" No Announcements were made!")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-1/3 inline-block text-right" }, [
      _c("a", { attrs: { href: "/#/announcements" } }, [
        _c("i", { staticClass: "fa fa-calendar" }),
        _vm._v(" View all Announcements")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }