var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "client_area" } }, [
    _c("h2", { staticClass: "text-gold text-center" }, [_vm._v("Client Area")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container mx-auto" },
      [
        _c(
          "div",
          { staticClass: "lg:w-1/3 md:w-1/2 sm:w-full inline-block pr-4" },
          [
            _c(
              "nav",
              {
                staticClass:
                  "wrapper-border rounded w-full text-white sm:hidden md:hidden lg:inline-block xl:inline-block"
              },
              [
                _c("ClientMenu", {
                  attrs: {
                    ulClass: "clientarea p-4",
                    liClass: "p-none",
                    navItems: _vm.navItems
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("router-view")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }