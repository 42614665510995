var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("AppHeader"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper-border text-center text-2xl p-4 mt-8" },
        [_vm._v("404 Page Not Found")]
      ),
      _vm._v(" "),
      _c("AppFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }