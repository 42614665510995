<template>
    <section id="textboxes">
      <div class="w-full bg-white mx-auto p-4 text-center">
          <div class="container mx-auto p-4">
            <!--Begin Item-->
            <div class="md:w-1/2 lg:w-1/3 inline-block align-top">
                  <div class=" text-center p-4 my-4 mr-4">
                    <h3><i class="fa fa-server"></i>Infrastructure</h3>
                <p>RayTech Hosting has partnered with the global leaders in datacenter technology to provide our customers with the most reliable, robust and completely redundant servers and networking. Join our ranks and take pride knowing your service will be available 24/7/365*</p>
                <p class="button text-center"><a href="/#/about-us" alt="About Us"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
            </div>
          </div><div class="md:w-1/2 lg:w-1/3 inline-block align-top">
                  <div class=" text-center p-4 my-4 mr-4">
                    <h3><i class="fa fa-cloud-upload"></i>Uptime Guarantee</h3>
                <p>RayTech Hosting is proud to bring you a 99.98% uptime. No questions - NO BS - IT JUST WORKS!</p>
                <p class="button text-center align-bottom"><a href="/#/faqs" alt="F.A.Q"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
            </div>
          </div><div class="md:w-1/2 lg:w-1/3 inline-block align-top">
                  <div class=" text-center p-4 my-4 mr-4">
                    <h3><i class="fa fa-life-ring"></i>Support Center</h3>
                <p>A one stop shop for all service related questions, updated based on the feedback of our great customers with one goal in mind - Bring our experience to a self service help panel. Still having issues? Submit a ticket and have a specialist assist you TODAY!</p>
                <p class="button text-center align-bottom"><a href="/#/opening-hours" alt="Opening Hours"><button class="bg-gold-dark px-4 py-2 rounded-full mt-2"><i class="fa fa-question-circle"></i> Learn More</button></a></p>
            </div>
          </div>
            <!--End Item-->
        </div>
      </div>
    </section>
</template>

<script>
    export default {
            name: 'textboxes'
    }
</script>
