var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "cartView" } }, [
    _c(
      "table",
      { staticClass: "wrapper-border w-full p-4" },
      _vm._l(_vm.order, function(product) {
        return _c("tr", [
          _c("td", [_vm._v(_vm._s(product.name))]),
          _c("td", [_vm._v(_vm._s(product.pricing.CAD.monthly))]),
          _c("td", [
            _c("i", {
              staticClass: "fa fa-close text-red",
              on: {
                click: function($event) {
                  return _vm.remove(product)
                }
              }
            })
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }