var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "lg:w-2/3 md:w-1/2 sm:w-full inline-block align-top",
      attrs: { id: "cart" }
    },
    [_c("h1", [_vm._v("Cart")]), _vm._v(" "), _c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }