<template>
  <li v-if="children.length > 0" :class="[ dropdown_trigger, liClass]">
    <template v-if="url">
      <router-link :to="url" class="text-white">
        <template v-if="icon">
          <i :class="icon"></i>
        </template> {{name}}
      </router-link>
    </template>
    <template v-else>
      <template v-if="icon">
        <i :class="icon"></i>
      </template>&nbsp;{{name}}
    </template>
    <nav-dropdown :children="children"  />
  </li>
  <li v-else :class="liClass">
    <router-link class="text-white" :to="url">
      <template v-if="icon">
        <i :class="icon"></i>
      </template> {{name}}
    </router-link>
  </li>
</template>

<script>
    export default {
        name: "navItem",
        data() {
          return {
            dropdown_trigger: 'dropdown_trigger'
          }
        },
        props: {
            name: {
                type: String,
                required: true,
                default: () => ''
            },
            icon: {
                type: String,
                required: false,
                default: () => ''
            },
            url: {
                type: String,
                required: false,
                default: () => ''
            },
            children: {
                type: Array,
                required: false,
                default: () => []
            },
            liClass: {
              type: String,
              required: false,
              default: () => ''
            }
        }
    }
</script>
