<template>
    <section id="featured" class="container mx-auto mt-8">
        <h1 class="text-gold wrapper-border text-center wow slideInLeft" data-wow-delay="1s">Featured Product</h1>
        <div class="md:w-1/2 lg:w-1/3 inline-block">
            <div class=" text-center wrapper-border p-4 my-4 mr-4">
                <img class="top-icon" src="../../assets/img/shared-hosting.png" alt="Shared Hosting" />
                <h3>Shared Hosting</h3>
                <div class="details text-white">
                    <span class="price block"><sup>$</sup>7<small>.79</small></span>
                    <span class="package">Basic</span>
                    <ul class="specs">
                        <li><i class="fa fa-caret-right"></i> 25GB HD Space</li>
                        <li><i class="fa fa-caret-right"></i> Unlimited Bandwith</li>
                        <li><i class="fa fa-caret-right"></i> Unlimited e-mail accounts</li>
                    </ul>
                </div>
                <a class="more" href="/#/shared-hosting"><button class="px-4 py-2 bg-gold-dark rounded-full">See More</button></a>
            </div>
        </div><div class="md:w-1/2 lg:w-1/3 inline-block">
            <div class="text-center wrapper-border p-4 my-4">
                <img class="top-icon" src="../../assets/img/vps_banner_img.png" alt="VPS Hosting"/>
                <h3>VPS Hosting</h3>
                <div class="details text-white">
                    <span class="price block"><sup>$</sup>7<small>.49</small></span>
                    <span class="package">SSD 1</span>
                    <ul class="specs">
                        <li><i class="fa fa-caret-right"></i> 1x 2.4GHz vCore</li>
                        <li><i class="fa fa-caret-right"></i> 2GB RAM</li>
                        <li><i class="fa fa-caret-right"></i> 20GB SSD</li>
                    </ul>
                </div>
                <a class="more" href="/#/vps"><button class="px-4 py-2 bg-gold-dark rounded-full">See More</button></a>
            </div>
        </div><div class="md:w-1/2 lg:w-1/3 inline-block">
            <div class="text-center wrapper-border p-4 my-4 ml-4">
                <img class="top-icon" src="../../assets/img/reseller.png" alt=""/>
                <h3>Reseller Hosting</h3>
                <div class="details text-white">
                    <span class="price block"><sup>$</sup>25<small>.99</small></span>
                    <span class="package">Beginner</span>
                    <ul class="specs">
                        <li><i class="fa fa-caret-right"></i> 50GB HD Space</li>
                        <li><i class="fa fa-caret-right"></i> 250GB Bandwith</li>
                        <li><i class="fa fa-caret-right"></i> Unlimited cPanel accounts</li>
                    </ul>
                </div>
                <a class="more" href="/#/reseller"><button class="px-4 py-2 bg-gold-dark rounded-full">See More</button></a>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
            name: 'featured'
    }
</script>
