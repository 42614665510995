<template><section id='cart' class='lg:w-2/3 md:w-1/2 sm:w-full inline-block align-top'>
    <h1>Cart</h1>
    <router-view></router-view>
  </section>
</template>

<script>
  export default {
    name: 'cart'
  }
</script>
