<template>
  <!--Begin Hosting Features-->
  <section id="hostingfeatures">
    <div class="container mx-auto">
      <h3 class="wrapper-border p-2 my-4 text-center text-gold wow fadeInLeft">Great features included with all plans</h3>
        <!--Begin Feature-->
        <template v-for="feature in features">
        <div class="xl:w-1/3 lg:w-1/3 md:w-1/3 sm:w-full md:h-half mb-3 inline-block align-top feature wow zoomInDown" :data-wow-delay="feature.wowdelay">
          <div class="wrapper-border p-4 h-full text-center m-4">
            <img :src="feature.img" class="img-responsive">
            <h4>{{feature.title}}</h4>
            <p class="font-light">{{feature.text}}</p>
          </div>
        </div>
      </template>
        <!--End Feature-->
      </div>
    </div>
  </section>
</template>

<script>
import features from '../language/en/_features'

export default {
  name: 'features',
  data() {
    return {
      features: features.items
    }
  }
}
</script>
