<template>
    <section id="login">
        <div class="flex mt-10">
            <div class="flex-1"></div>
            <div class="flex-1 wrapper-border px-4 py-4 text-center text-white">
              <template v-if="error.active == true">
              <div class="bg-red-lightest border border-red-light text-red-dark px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold">Error</strong>
                <span class="block sm:inline">{{ error.message }}</span>
                <span class="absolute pin-t pin-b pin-r px-4 py-3">
                  <svg v-on:click="closeError" class="fill-current h-6 w-6 text-red" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
            </template>
                <img class="px-8 py-4" src="../assets/img/wlogo.png" />
                <h2 class="text-white text-center">Login</h2>
                <form @submit.prevent="handleSubmit">
                    <fieldset><input type="text" v-model="user.name" size="20" placeholder="Email Address" /></fieldset>
                    <fieldset><input type="password" v-model="user.passw" size="20" autocomplete="off" placeholder="Password" /></fieldset>
                    <fieldset><button class="bg-blue hover:bg-blue-dark text-black font-bold py-2 px-4 rounded" type="submit">Login</button></fieldset>
                </form>
            </div>
            <div class="flex-1"></div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'login',
        data() {
          return {
            user: {
                name: "",
                passw: "",
            }
          }
        },
        methods: {
            handleSubmit() {
                this.$store.dispatch('client/login', {auth: { email: this.user.name, password: this.user.passw}})
                if(this.$store.getters['client/isLogged'] === true) {
                  this.$router.push({name: 'manage'})
                  this.$router.go(1)
                }
            },
            closeError() {
                this.$store.commit('clearError')
            }
        },
        computed: {
          error: function() {
              if (this.$store.getters['errorsActive'] == true) {
                return this.$store.getters['errors']
              }
              return {active: false}
          }
        },
        created: function() {
          if(this.$store.getters['client/isLogged'] === true) {
            this.$router.push({name: 'manage'})
            this.$router.go(1)
          }
        }
    }
</script>
