import Axios from 'axios'
import Vue from 'vue'

Vue.use(Axios)

export default {
  namespaced: true,
  state: {
    order: [],
    ordered: false
  },
  mutations: {
    addToOrder(state, payload) {
      state.order.push(payload)
    },
    removeFromOrder(state, payload) {
      state.order.splice(payload, 1)
    }
  },
  actions: {
    add(context, pid) {
      var product = context.rootState.products.products.filter(function(product) {
          let output = (product.pid == pid) ? product : null
          return output
      })
      context.commit('addToOrder', product[0])
    },
    remove(context, data) {
      context.commit('removeFromOrder', data)
    },
    addOrder(context) {
      context.rootState.cart.order.foreach(function(product) {
          console.log(product.pid)
      })
      Axios.post(context.rootState.config.apiBaseUrl, {}, {
          headers: {
              Authorization: context.rootState.config.basicAuth
          },
          params: {
              username: context.rootState.config.username,
              password: context.rootState.config.password,
              accesskey: context.rootState.config.accesskey,
              action: 'AddOrder',
              responsetype: 'json',
              clientid: context.rootState.client.user_data.id,
              paymentmethod: context.rootState.config.paymentmethod,
              //pid:
          },
          withCredentials: true
          }).then((response) => {

          })
    }
  },
  getters: {
    order(state) {
      return state.order
    }
  }
}
