export default {
  items: [
    {
      title: "Instant Setup",
      text: "Your account will be provisioned immediately after your first invoice is paid.",
      img: require("../../assets/img/hostingplans/features/instantsetup.png"),
      wowdelay: "0.1s"
    },
    {
      title: "99.98% Uptime Guarantee",
      text: "RayTech Hosting and its partner datacenters agreed to provide the most resilient infastructure for the lowest prices possible. All to help our great customers.",
      img: require("../../assets/img/hostingplans/features/uptime.png"),
      wowdelay: "0.3s"
    },
    {
      title: "Full User Support",
      text: "Having issues? check out our Self Help, and if you cant figure out the problem, or need the fix in a hurry - place a ticket and a specialist will assist you.",
      img: require("../../assets/img/hostingplans/features/support.png"),
      wowdelay: "0.5s"
    },
    {
      title: "Website Building Tools",
      text: "Design stunning sites for yourself and your customers, withought the need to touch code - all for free!",
      img: require("../../assets/img/hostingplans/features/websitebuilders.png"),
      wowdelay: "0.3s"
    },
    {
      title: "Free Domain Transfers",
      text: "Transfer your domain to our servers and let us handle your renewals. Never worry about when the bill is due again!",
      img: require("../../assets/img/hostingplans/features/domaintransfer.png"),
      wowdelay: "0.5s"
    },
    {
      title: "Managed Servers",
      text: "All of our shared servers are managed by our proffesional staff, this ensures complete security, guaranteed compliance, and peace of mind for you and your customers.",
      img: require("../../assets/img/hostingplans/features/managed.png"),
      wowdelay: "0.7s"
    }
  ]
}
