var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "dropdown bg-black" },
    [
      _vm._l(_vm.children, function(item, index) {
        return [
          _c("nav-dropitem", {
            attrs: {
              liClass: "p-3",
              name: item.name,
              icon: item.icon,
              url: item.url,
              children: item.children
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }