<template>
    <section id="domain_checker">
        <div class="container bg-grey-darker mx-auto mb-4 wrapper-border px-4 py-4 text-center text-white">
            <form @submit.prevent="handleSubmit">
                See if your domain is available: <input type="text" v-model="domain" class="px-2 py-2" size="20" />
                <button class="bg-blue hover:bg-blue-darker rounded px-4 py-2 font-semibold" type="submit" >Go </button>
            </form>
        </div>
        <modal width="300px" height="auto" name="domain_checker">
          <div class="p-2">
          Domain {{ domain }} is
          <template v-if="status == 'unavailable'">
            <span class="text-red">{{ status }}</span>
          </template>
          <template v-else>
            <span class="text-green">{{ status }}</span>
          </template>
        </div>
        </modal>
    </section>
</template>
<script>
  import VModal from 'vue-js-modal'
  import Axios from 'axios'
  import Vue from 'vue'

  Vue.use(Axios)
  Vue.use(VModal)

  export default {
    name: 'domain_checker',
    data() {
      return {
        domain: '',
        status: ''
      }
    },
    methods: {
      handleSubmit() {
        let config = this.$store.getters['baseConfig']
        Axios.post(config.apiEndPoint, {}, {
          headers: {
              Authorization: config.auth
          },
          params: {
              username: config.username,
              password: config.password,
              accesskey: config.accesskey,
              action: 'DomainWhois',
              domain: this.domain,
              responsetype: 'json'
          },
          withCredentials: true
          }).then((response) => {
            this.status = response.data.status
            this.$modal.show('domain_checker', {draggable: true})
          })
      }
    }
  }
</script>
