<template>
    <ul class="dropdown bg-black">
        <template v-for="(item,index) in children">
            <nav-dropitem liClass="p-3" :name="item.name" :icon='item.icon' :url="item.url" :children="item.children" />
        </template>
    </ul>
</template>

<script>
    export default {
      name: 'navDropdown',
      props: {
        children: {
          type: Array,
          required: true,
          default: () => []
        }
      },
    }
</script>
