export default {
    items: [
        {
            name: 'Home',
            icon: 'fa fa-home',
            url: '/home'
        },
        {
            name: 'Products',
            url: '/products',
            children: [
                {
                    name: 'Website Hosting',
                    icon: 'fa fa-cloud',
                    url: '/products/website-hosting'
                },
                {
                    name: 'Reseller Hosting',
                    icon: 'fa fa-users',
                    url: '/products/reseller-hosting'
                },
                {
                    name: 'VPS Hosting',
                    icon: 'fa fa-server',
                    url: '/products/vps-hosting',
                    children: [
                      {
                        name: 'SSD VPS',
                        url: '/products/vps-hosting/ssd'
                      },
                      {
                        name: 'Cloud VPS',
                        url: '/products/vps-hosting/cloud'
                      },
                      {
                        name: 'Cloud Ram VPS',
                        url: '/products/vps-hosting/ram'
                      }
                    ]
                },
                /*{
                    name: 'Dedicated Hosting',
                    url: '/dedicated-hosting'
                }*/
            ]
        },
        {
            name: 'Services',
            url: '',
            children: [
              {
                name: 'Website Design',
                url: '/services/website-design'
              },
              {
                name: 'Website Services',
                url: '/services/website-services'
              },
              {
                name: 'Request a quote',
                url: '/contact-form'
              }
            ]
        },
        {
            name: 'Support',
            url: '',
            children: [
                {
                    name: 'Knowledgebase',
                    icon: 'fa fa-life-saver',
                    url: '/knowledgebase'
                }
            ]
        },
        {
            name: 'About Us',
            url: '/about-us'
        },
        {
            name: 'Client Area',
            url: '/client',
            children: [
                {
                    name: 'Login',
                    icon: 'fa fa-sign-in',
                    url: '/login',
                }
            ],
        },
    ]
}
