var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass:
        "block bg-grey-darkest text-white sm:block md:block lg:inline-block xl:inline-block"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "nav",
        {
          staticClass:
            "hidden text-white sm:hidden md:hidden lg:inline-block xl:inline-block"
        },
        [
          _c("Menu", {
            attrs: { liClass: "py-5 pr-5", navItems: _vm.navItems }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "block text-white sm:block md:block lg:inline-block xl:inline-block",
        attrs: { id: "logo" }
      },
      [
        _c("img", {
          staticClass: "pl-4 py-2",
          attrs: { src: require("../assets/img/wlogo.png") }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }