var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "vpsHosting" } },
    [
      _vm.mainPath == "/products/vps-hosting"
        ? [_vm._m(0)]
        : [_c("router-view")]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full page-header" }, [
      _c("h1", { staticClass: "text-gold text-center py-2" }, [
        _vm._v("VPS Hosting")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }