import {WOW} from 'wowjs/dist/wow.min.js';
import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import axios from 'axios'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie';
import NavItem from './components/NavItem'
import NavDropDown from './components/NavDropDown'
import NavDropItem from './components/NavDropItem'
import Features from './components/Features'
// Tell Vue to use the plugin
Vue.use(VueCookie)
Vue.use(Vuex)
Vue.prototype.$http = axios
Vue.component('nav-item', NavItem)
Vue.component('nav-dropdown', NavDropDown)
Vue.component('nav-dropitem', NavDropItem)
Vue.component('features', Features)

// WOW.js implementation
const wow = new WOW().init()
window.wow = wow

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
