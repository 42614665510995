<template>
  <div class='wrapper-border p-4 text-center'>
    <p>You have been logged out!</p>
    <button class="bg-blue-dark px-4 py-2" @click="next"><i class="fa fa-thumbs-o-up"></i>OK</button>
  </div>
</template>

<script>
  export default {
    name: 'logout',
    computed: {
      logged: function() {
        return this.$store.getters['client/isLogged']
      }
    },
    methods: {
      next: function() {
        this.$router.push({name: 'home'})
        this.$router.go(1)
      }
    },
    created: function() {
      this.$store.dispatch('client/logout')
    }
  }
</script>
