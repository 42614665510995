export function errorsActive(state) {
    return state.error.active
}
export function errors(state) {
  return state.error
}
export function getAuth(state) {
  return state.client.auth
}
export function baseConfig(state) {
  return state.config
}
